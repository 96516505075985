.tac{
    background: #efefef;
    color: #626262;
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 14px;
}

.tac-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 3rem;
}

.fs-22 {
    font-size: 1.3rem;
}

.fs-20 {
    font-size: 1.2rem;
}

.cmn-Mrg{
    margin-bottom: 0.5rem;
}

.mg-rgt{
    margin-right: 1rem;
}

.mg-lft{
    margin-left: 1.5rem;
}

.mrg-tp{
    margin-top: 0.5rem;
}

.mg_btm_one{
    margin-bottom: 1rem;
}

.mg_btm_two{
    margin-top: 1rem;
}

.gapProvOne{
    gap: 0.4rem;
}

.gapProvTwo{
    gap: 0.6rem;
}

.gapProvThree{
    gap: 0.8rem;
}

.tac-contentContainer-CommonSty{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    float: left;
    width: 100%;
}

.tac-contentCommonSty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.tac-content-copyRight{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}