/* .trending-container {
    padding: 1rem 3rem
}

.trending-heading-col {
    display: flex;
    justify-content: space-between;
}

.trending-txt-blue {
    color: #4946C3;
}

.trending-image-container {
    display: flex;
    gap: 5px;
    padding-top: 10px
} */

.subHeading {
    display: flex;
    flex-direction: column;
    margin: 2rem 3rem;
}

.subHeading-heading {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.subHeading-heading-h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
}

.subHeading-heading-viewAll {
    font-size: 12px;
    color: #4946C3;
    cursor: pointer;
    font-weight: 600;
}

@media (min-width : 481px) and (max-width : 909px) {
    .subHeading {
        margin: 1.5rem 2rem;
    }

    .subHeading-heading-h1 {
        font-size: 1.3rem;
    }

    .subHeading-heading-viewAll {
        font-size: 0.75rem;
        font-weight: 500;
    }
}

@media (min-width : 910px) and (max-width : 1024px) {
    .subHeading {
        display: flex;
        flex-direction: column;
        margin: 2rem 3rem;
    }

    .subHeading-heading-h1 {
        font-size: 25px;
    }

    .subHeading-heading-viewAll {
        font-size: 13px;
    }
}

@media (min-width : 320px) and (max-width : 465px){
    .subHeading{
        margin: 2rem 1rem;
    }

    .subHeading-heading{
        align-items: center;
    }

    .subHeading-heading-h1{
        font-size: 20px;
    }

    .subHeading-heading-viewAll{
        font-size: 11px;
        text-decoration: underline;
    }
}