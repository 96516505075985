.pricingForPlans-container{
    display: flex;
    flex-direction: column;
    margin: 0rem 3rem;
}

.pricingForPlans-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0rem 1rem 0rem;
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #666666;
}

.pricingForPlans-subHeading{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #666666;
    margin-bottom: 2rem;
}

.pricingForPlans-infoCardsContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.pricingForPlans-cardsCommonSty{
    display: flex;
    flex-direction: column;
    height: 400px;
    border: 1px solid #D9DBE0;
    border-radius: 4px;
    width: 100%;
    padding: 12px;
}

.pricingForPlans-cardHeadingCommonSty{
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #000000;
    height: 10%;
}

.pricingForPlans-cardSubHeadingCommonSty{
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    height: 20%;
}

.pricingForPlans-cardsOptionOneCommonSty{
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 25%;
}

.pricingForPlans-cardCommonSty-one{
    font-size: 13px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    color: #666666;
}

.pricingForPlans-cardCommonSty-two{
    border: 1px solid #D9DBE0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 9px;
    border-radius: 5px;
    width: 40%;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #666666;
}

.pricingForPlans-cardsOptionTwoCommonSty{
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 25%;
}

.pricingForPlans-cardCommonSty-three{
    border: 1px solid #D9DBE0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 9px;
    border-radius: 5px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}

.pricingForPlans-priceCommonSty{
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #666666;
    height: 10%;
}

.pricingForPlans-buttonCommonSty{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 13px;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    height: 10%;
}

.pfp-addStylesTwo{
    background: #ffffff;
    border: 1px solid #C8C8C8;
}

.pfp-addStyles{
    background: #ED652B;
    color: #ffffff;
    border: 1px solid #ED652B;
}

.pfp-addCardStyles{
    justify-content: space-between;
}