.priPol{
    background: #efefef;
    color: #626262;
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 14px;
}

.priPol-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 3rem 5rem 3rem;
}

.fs-22 {
    font-size: 1.3rem;
}

.fs-20 {
    font-size: 1.2rem;
}

.cmn-Mrg{
    margin-bottom: 0.5rem;
}

.dotCmn-mrg{
    margin-top: 0.15rem;
}

.mg-rgt{
    margin-right: 1rem;
}

.mg-lft-two{
    margin-left: 1.8rem;
}

.mg-lft{
    margin-left: 1.5rem;
}

.mrg-tp{
    margin-top: 0.5rem;
}

.mg-tpBtm{
    margin: 0.2rem;
}

.mg_btm_one{
    margin-bottom: 1rem;
}

.mg_btm_two{
    margin-top: 1rem;
}

.gapProvOne{
    gap: 0.4rem;
}

.gapProvTwo{
    gap: 0.6rem;
}

.gapProvThree{
    gap: 0.8rem;
}

.borderProv{
    border-top: 1px solid gray;
}

.priPol-contentContainer-CommonSty{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.priPol-contentCommonSty {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    justify-content: center;
    align-items: flex-start;
}

.priPol-inspireContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.priPol-hyperlink{
    color: #0000EE;
    text-decoration: underline;
    cursor: pointer;
}

.priPol_content_commonSty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1.8rem;
    width: 100%;
}

.priPol_content_infoCmnSty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.priPol_index_info{
    display: flex;
    flex-direction: row;
}

.priPol_infoContainer{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.priPol-info-points{
    display: flex;
    flex-direction: column;
}

.flexrowCmnSty{
    display: flex;
    flex-direction: row;
}

.flex-columnCmnSty{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.priPol_content_table_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    border: 1px solid gray;
}

.priPol_table_row{
    display: grid;
    grid-template-columns: 0rem 1.3fr 8.7fr 0rem;
    min-height: 3rem;
    max-height: 7rem;
    height: auto;
}

.priPol_table_columnOne{
    display: flex;
    flex-direction: row;
    grid-column: 2/3;
    padding-left: 10px;
    border-right: 1px solid gray;
    width: 100%;
}

.priPol_table_columnTwo{
    display: flex;
    flex-direction: column;
    grid-column: 3/4;
    margin-left: 10px;
}
