.vdp-menu-items {
    color: black;
    text-decoration: none;
}

.vdp-container {
    margin: 0rem 3rem;
}

.vdp-container-searchBar {
    display: flex;
    flex-direction: row;
    margin: 1.3rem 0rem;
    border: 1px solid #D9DBE0;
    border-radius: 5px;
    padding: 6px 0px;
    height: 3rem;
}

.vdp-container-video {
    display: grid;
    grid-template-columns: 0 7.2fr 2.8fr 0;
    height: 85vh;
    margin-top: 4rem;
    max-height: 85vh;
}

.vdp-container-video-player {
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
    display: flex;
    grid-column: 1/3;
    height: 100%;
    position: relative;
    width: 100%;
}

.vdp-container-video-player video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.vdp-container-video-imageCollection {
    align-items: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    grid-column: 3/5;
    height: 85vh;
    justify-content: center;
    max-height: 85vh;
    padding: 3rem 2rem;
}

.vdp-thumbnail-side {
    width: 100%;
    height: 0;
    padding-bottom: 25vh;
    position: relative;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.vdp-thumbnail-side video {
    position: absolute;
    border-radius: 10px;
    height: 25vh;
    object-fit: cover;
    width: 100%;
}

.vdp-thumbnail-side img {
    border-radius: 10px;
    height: 25vh;
    object-fit: cover;
    width: 100%;
}

.vdp-container-optionsAndContentId {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0rem;
    width: 72%;
    justify-content: space-between;
    align-items: flex-start;
}

.vdp-container-contentId {
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
}

.vdp-contentId {
    font-size: 15px;
    font-weight: 600;
    color: #363636;
    width: fit-content;
    min-width: fit-content;
    max-width: fit-content;
}

.vdp-container-options {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: flex-end;
}

.vdp-options-icons {
    align-items: center;
    border: 1px solid #d9dbe0;
    border-radius: 5px;
    color: #666;
    cursor: pointer;
    display: flex;
    font-family: open Sans;
    font-size: 14px;
    margin-right: 14px;
    padding: 7px 10px;
}

.vdp-container-detailsCommonSty {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
}

.vdp-detailsContent-container-cmnSty {
    display: flex;
    flex-direction: row;
    width: 50%;
}

.vdp-detailsContent-commonSty {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

.vdp-heading {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #363636;
}

.vdp-details {
    font-size: 13px;
    color: #363636;
    margin-bottom: 6px;
    padding-right: 25px;
    word-break: break-all;
}

.vdp-details-priceDesc {
    /* font-size: 13px;
    color: #363636;
    word-break: break-all; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
}

.vdp-contributor-nameInitials {
    font-size: 12px;
    font-weight: 600;
    color: #4946C3;
    border: 1px solid #DCDBFF;
    background: #DCDBFF;
    padding: 6px;
    border-radius: 25px;
    margin-right: 10px;
    text-transform: uppercase;
}

.vdp-contributor-name {
    font-size: 13px;
    font-weight: 400;
    color: #363636;
}

.vdp-details-addSty {
    border: 1px solid #D9DBE0;
    padding: 2px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 11px;
}

.vdp-container-cc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.vdp-container-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

.vdp-descAddSty {
    color: #363636;
    font-size: .8125rem;
    word-break: break-all;
}

.vdp-container-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem;
}

.vdp-details-keywordsSty {
    border: 1px solid #D9DBE0;
    padding: 4px 10px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 13px;
    border-radius: 5px;
    font-weight: 500;
}

.vdp-details-keywordsSty:hover {
    cursor: pointer;
    border: 1px solid #ED652B;
}

.imageDetailsPage-container-bundleDeals {
    margin-bottom: 3rem;
}

.vdp-container-bundleDeals {
    margin-bottom: 3rem;
}

.vdp-bundleDeals-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 23px;
    font-weight: 600;
}

.vdp-container-similarVideos {
    margin-bottom: 3rem;
}

.vdp-similarVideos-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 10px;
}

.vdp-container-mftc {
    margin-bottom: 3rem;
}

.vdp-container-titleAndPrice {
    display: flex;
    flex-direction: row;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.vdp-contentId-value {
    font-size: 0.8rem;
    font-weight: 500;
}

.vdp-addToCartBtn {
    background: #ED652B;
    color: #ffffff;
    width: 103px;
    height: 35px;
    float: left;
    font-weight: 700;
    border: 1px solid #ED652B;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}

@media (min-width : 481px) and (max-width : 768px) {
    .vdp-container {
        margin: 0rem 1.5rem;
    }

    .vdp-container-searchBar {
        display: flex;
        flex-direction: row;
        margin: 1rem 0rem;
        border: 1px solid #D9DBE0;
        border-radius: 5px;
        align-items: center;
        height: 2.5rem;
        padding: 1px;
    }

    .vdp-container-video {
        margin-top: 2.5rem;
        max-height: 400px;
    }

    .vdp-container-video-player {
        max-height: 400px;
        border-radius: 10px;
    }

    .vdp-container-video-player video {
        border-radius: 10px;
    }

    .vdp-container-video-imageCollection {
        margin: 0rem 0.3rem;
        max-height: 400px;
    }

    .vdp-container-video-imageCollection img {
        max-height: 100px;
        margin-bottom: 0.3rem;
        object-fit: cover;
        border-radius: 10px;
    }

    .vdp-container-optionsAndContentId {
        margin: 0.3rem 0rem;
        width: 72%;
    }

    .vdp-container-contentId {
        width: 100%;
        gap: 0.3rem;
    }

    .vdp-contentId {
        font-size: 0.75rem;
        font-weight: 600;
        color: #363636;
    }

    .vdp-contentId-value {
        font-size: 0.65rem;
        font-weight: 500;
    }

    .vdp-options-icons {
        font-size: 12px;
        padding: 5px 7px;
        margin-right: 7px;
    }

    .vdp-addToCartBtn {
        width: 70px;
        height: 28px;
        font-weight: 600;
        border: 1px solid #ED652B;
        font-size: 11px;
    }

    .vdp-container-titleAndPrice {
        width: 80%;
    }

    .vdp-heading {
        font-size: 0.8rem;
        margin-bottom: 0rem;
    }

    .vdp-details {
        font-size: 0.7rem;
        margin-right: 1.2rem;
    }

    .vdp-container-desc {
        width: 80%;
        margin-bottom: 0.7rem;
    }

    .vdp-details-priceDesc {
        font-size: 0.7rem;
        color: #363636;
        word-break: break-all;
    }

    .vdp-container-detailsCommonSty {
        margin-bottom: 0.7rem;
    }

    .vdp-contributor-nameInitials {
        font-size: 0.7rem;
        padding: 4px;
        margin-right: 3px;
    }

    .vdp-contributor-name {
        font-size: 0.7rem;
    }

    .vdp-similarVideos-heading {
        font-size: 1.15rem;
    }
}

@media (min-width : 769px) and (max-width : 909px) {
    .vdp-container {
        margin: 0rem 1.5rem;
    }

    .vdp-container-searchBar {
        display: flex;
        flex-direction: row;
        margin: 1rem 0rem;
        border: 1px solid #D9DBE0;
        border-radius: 5px;
        align-items: center;
        height: 2.5rem;
        padding: 1px;
    }

    .vdp-container-video {
        margin-top: 2.5rem;
        max-height: 420px;
    }

    .vdp-container-video-player {
        max-height: 420px;
        border-radius: 10px;
    }

    .vdp-container-video-player video {
        border-radius: 10px;
    }

    .vdp-container-video-imageCollection {
        margin: 0rem 0.3rem;
        max-height: 420px;
    }

    .vdp-container-video-imageCollection img {
        max-height: 120px;
        margin-bottom: 0.3rem;
        object-fit: cover;
        border-radius: 10px;
    }

    .vdp-container-optionsAndContentId {
        margin: 0.3rem 0rem;
        width: 72%;
    }

    .vdp-container-contentId {
        width: 100%;
        gap: 0.3rem;
    }

    .vdp-contentId {
        font-size: 0.75rem;
        font-weight: 600;
        color: #363636;
    }

    .vdp-contentId-value {
        font-size: 0.65rem;
        font-weight: 500;
    }

    .vdp-options-icons {
        font-size: 12px;
        padding: 5px 7px;
        margin-right: 7px;
    }

    .vdp-addToCartBtn {
        width: 70px;
        height: 28px;
        font-weight: 600;
        border: 1px solid #ED652B;
        font-size: 11px;
    }

    .vdp-container-titleAndPrice {
        width: 80%;
    }

    .vdp-heading {
        font-size: 0.8rem;
        margin-bottom: 0rem;
    }

    .vdp-details {
        font-size: 0.7rem;
        margin-right: 1.2rem;
    }

    .vdp-container-desc {
        width: 80%;
        margin-bottom: 0.7rem;
    }

    .vdp-details-priceDesc {
        font-size: 0.7rem;
        color: #363636;
        word-break: break-all;
    }

    .vdp-container-detailsCommonSty {
        margin-bottom: 0.7rem;
    }

    .vdp-contributor-nameInitials {
        font-size: 0.7rem;
        padding: 4px;
        margin-right: 3px;
    }

    .vdp-contributor-name {
        font-size: 0.7rem;
    }

    .vdp-similarVideos-heading {
        font-size: 1.15rem;
    }
}

@media (min-width : 910px) and (max-width : 1024px) {
    .vdp-container {
        margin: 0rem 2.5rem;
    }

    .vdp-container-video {
        margin-top: 3rem;
    }

    .vdp-container-video-player {
        border-radius: 10px;
    }

    .vdp-container-video-player video {
        border-radius: inherit;
    }

    .vdp-container-video-imageCollection {
        margin: 0rem 0.8rem;
        border-radius: 10px;
    }

    .vdp-container-video-imageCollection img {
        height: 25vh;
        border-radius: 10px;
        margin-bottom: 0.8rem;
    }

    .vdp-container-optionsAndContentId {
        width: 72%;
        margin: 0.5rem 0rem 0.8rem 0rem;
    }

    .vdp-container-contentId {
        gap: 0.4rem;
        width: 100%;
    }

    .vdp-contentId {
        font-size: 0.8rem;
    }

    .vdp-contentId-value {
        font-size: 0.7rem;
        font-weight: 500;
    }

    .vdp-options-icons {
        font-size: 0.8rem;
        padding: 6px;
        margin-right: 13px;
    }

    .vdp-addToCartBtn {
        height: 35px;
        font-size: 0.8rem;
    }

    .vdp-heading {
        font-size: 0.9rem;
        margin-bottom: 4px;
    }

    .vdp-details {
        font-size: 0.8rem;
    }

    .vdp-details-priceDesc {
        font-size: 0.8rem;
    }

    .vdp-similarImages-heading {
        font-size: 21px;
        margin-bottom: 0px;
    }
}

@media (min-width : 1025px) and (max-width : 1200px) {
    .vdp-container-video-imageCollection {
        margin: 0rem 1rem;
        border-radius: 10px;
    }

    .vdp-container-video-imageCollection img {
        border-radius: 10px;
    }

    .vdp-container-optionsAndContentId {
        width: 72%;
    }
}

@media (min-width : 320px) and (max-width : 465px) {
    .vdp-container {
        margin: 0rem 1rem;
    }

    .vdp-container-searchBar {
        margin: 1rem 0rem;
        height: 2.5rem;
        padding: 2px 0px;
    }

    .vdp-container-video-player {
        height: 50vh;
    }

    .vdp-container-video-imageCollection {
        display: none;
    }

    .vdp-container-optionsAndContentId {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0.5rem 0;
        width: 100%;
    }

    .vdp-container-options {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .vdp-options-icons {
        font-size: 12px;
        padding: 5px 11px;
        margin-right: 0rem;
    }

    .vdp-container-contentId {
        margin-bottom: 0.5rem;
    }

    .vdp-contentId {
        font-size: 13px;
        font-weight: 600;
    }

    .vdp-contentId-value {
        font-size: 11px;
        font-weight: 500;
    }

    .vdp-addToCartBtn {
        width: 95px;
        height: 32px;
        font-weight: 600;
        font-size: 0.8rem;
    }

    .vdp-container-titleAndPrice {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .vdp-heading {
        font-size: 13px;
        margin-bottom: 0px;
    }

    .vdp-details {
        font-size: 12px;
        margin-bottom: 0px;
        padding-right: 0px;
    }

    .vdp-details-priceDesc {
        font-size: 12px;
    }

    .vdp-container-desc {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .vdp-container-detailsCommonSty {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
        width: 100%;
        margin-bottom: 0.5rem;
        gap: 0.5rem;
    }

    .vdp-detailsContent-container-cmnSty {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .vdp-contributor-nameInitials {
        font-size: 11px;
        padding: 4px;
        margin-right: 5px;
    }

    .vdp-contributor-name {
        font-size: 12px;
    }

    .vdp-similarImages-heading {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 2rem;
    }

    .vdp-container-video {
        display: flex;
        margin-top: 2rem;
        max-height: 90vh;
        height: 50vh;
    }
}

.vdp-detailsContent-price-selection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: none;
}