.adminTableViewPopup {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.adminTableViewPopup-close {
    cursor: pointer;
}

.adminTableViewPopup::-webkit-scrollbar {
    display: none;
}

.adminTableViewPopup-headingAndClose {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    z-index: 1000;
}

.adminTableViewPopup-heading {
    font-family: 'Open Sans';
    font-size: 23px;
    font-weight: 700;
    color: #000000;
}

.adminTableViewPopup-stepper {
    margin-bottom: 15px;
    z-index: 1000;
}

.popup-content {
    width: 100%;
    display: grid;
    grid-template-columns: 0px 1fr 1fr 0px;
}

.popup-contentInfo {
    display: flex;
    flex-direction: column;
    grid-column: 2/3;
}

.popup-contentInfo-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.popup-contentInfo-commonSty {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
}

.popup-contentInfo-resCodCommonSty {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.popup-contentInfo-forDurCommonSty {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.headSpanCommonSty {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #383838;
}

.downSpanCommonSty {
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    font-weight: 400;
    color: #666666;
}

.popup-contentInfo-inputFields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0.6rem;
    width: 95%;
}

.popup-inputCommonSty {
    height: 35px;
    width: 100%;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 6px 10px;
    font-family: 'Open Sans', sans-serif;
    color: #888888;
    outline: none;
}

.descriptionAddStyle {
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 6px 10px;
    font-family: 'Open Sans', sans-serif;
    color: #888888;
    outline: none;
    width: 100%;
    resize: none;
}

.checks_descriptionAddStyle {
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 6px 10px;
    font-family: 'Open Sans', sans-serif;
    color: #888888;
    outline: none;
    width: 100%;
    height: 85px;
    resize: none;
}

.popup-acceptRejectNextBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.popup-acceptReject {
    display: flex;
}

.popup-commonBtbSty {
    height: 40px;
    width: 100px;
    display: flex;
    flex-direction: row;
    font-family: 'Open Sans', sans-serif;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    color: #444444;
    font-size: 14px;
    margin-right: 10px;
}

.popupAcceptDisable {
    opacity: 50%;
}

.enableButton {
    cursor: pointer;
}

.popup-nextBtnSty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 120px;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #FFFFFF;
    font-weight: 500;
    background: #4946C3;
    border: 1px solid #4946C3;
    border-radius: 5px;
    cursor: pointer;
}

.popup-contentPreview {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-column: 3/4;
    border-radius: 10px;
}

.popup-contentPreview video {
    border-radius: 10px;
}

.popup-cc-detailsPreview {
    height: 150px;
    width: 80%;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
}

.popup-cp-imageAndTagContainer {
    height: 150px;
    width: 80%;
    position: relative;
}

.popup-cp-imageAndTagContainer img {
    height: 100%;
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
}

.premium-tag {
    font-size: 0.7rem;
    font-weight: 600;
    background: red;
    color: #ffffff;
    padding: 0rem 0.2rem;
    position: absolute;
    top: 0px;
    right: 0px;
    justify-content: center;
    align-items: center;
}

.premium-tag-image {
    border-top-right-radius: 10px;
}

.popup-close {
    cursor: pointer;
}

.adminTableDeletePopup {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.adminTableModal-headingAndClose {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.adminTableModal-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.adminTableModal-close {
    cursor: pointer;
}

.adminTableDeletePopup-cancelDeleteBtn {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.adminTableDeletePopup-delete {
    font-family: 'Open Sans', sans-serif;
    width: 100px;
    height: 35px;
    border: 1px solid #4946C3;
    border-radius: 5px;
    color: #ffffff;
    background: #4946C3;
    margin-left: 10px;
    cursor: pointer;
}

.adminTableDeletePopup-cancel {
    font-family: 'Open Sans', sans-serif;
    width: 100px;
    height: 35px;
    border: 1px solid #F1F1F1;
    cursor: pointer;
    border-radius: 5px;
    color: #444444;
    background: #ffffff;
}

.popupVisibility {
    display: grid;
    grid-template-columns: 0px 0.6fr 0.4fr 0px;
}

.popupVisibility-content {
    display: flex;
    flex-direction: column;
    grid-column: 2/3;
}

.popupVisibility-contentPreview {
    display: flex;
    flex-direction: column;
    grid-column: 3/4;
}

.popupVisibility-content-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
}

.popupVisibility-content-subHeading {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #383838;
    margin-bottom: 1.5rem;
}

.popupVisibility-contentOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 0px 0px;
}

.popupVisibility-contentOptions-commonSty {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #E3E3E3;
    height: 95px;
    width: 339px;
    margin-bottom: 15px;
    border-radius: 5px;
}

.popupVisibility-contentCommonSty {
    display: flex;
    flex-direction: column;
}

.popupVisibility-contentHeadingCommonSty {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.popupVisibility-contentSubHeadingCommonSty {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.popupVisibility-contentOptions-inputField {
    margin-top: 16px;
    margin-bottom: 32px;
}

.popupVisibility-contentOptions-inputField label {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #383838;
}

.popupVisibility-contentOptions-inputField input {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 339px;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    padding-left: 12px;
    font-family: 'Open Sans';
    font-size: 14px;
    outline: none;
}

.popupVisibility-contentOptions-inputField input::-webkit-outer-spin-button,
.popupVisibility-contentOptions-inputField input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.popupVisibility-contentOptions-inputField input[type=number] {
    -moz-appearance: textfield;
}

.popupVisibility-contentPreview img {
    height: 160px;
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
}

.popupVisibility-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.popupVisibility-buttons-AddSty {
    margin-top: 11rem;
}

.popupVisibility-backBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    height: 39px;
    width: 117px;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    color: #444444;
    background: #ffffff;
    cursor: pointer;
}

.popupVisibility-publishBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    width: 110px;
    height: 39px;
    background: #4946C3;
    border-radius: 5px;
    color: #ffffff;
    outline: none;
    border: 1px solid #4946C3;
    margin-left: 10px;
    cursor: pointer;
}

.rejectPopup-headingCrossBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    cursor: pointer;
}

.rejectPopup-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    font-weight: 600;
    color: #000000;
}

.rejectPopup-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.rejectPopup-buttons button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 115px;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
}

.rejectPopup-cancelBtn {
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    background: #ffffff;
    color: #444444;
    margin-right: 10px;
}

.rejectPopup-rejectBtn {
    border: 1px solid #4946C3;
    border-radius: 5px;
    background: #4946C3;
    color: #ffffff;
}

.popup-contentInfo-inputFields {
    gap: 0.5rem;
}

.popup-contentInfo-inputFieldsAndErroCommonSty {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.popup-contentInfo-inputFieldsErrorCommonSty {
    font-family: 'Open Sans', sans-serif;
    height: 10px;
    font-size: 11px;
    color: red;
}

.popupVisibility-dateSelector {
    display: flex;
    gap: 10px;
}

.popupVisibility-dateSelector input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C8C7C7;
    outline: none;
    width: 125px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #000000;
    margin-top: 6px;
    cursor: pointer;
}

.popupVisibility-dateAndTimeSelector {
    display: flex;
    flex-direction: row;
}

.popup-contentPreview-mediaDataInfo {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 1px;
    float: left;
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: 600;
    width: 80%;
}

.popup-content-rowTwo {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    margin-top: 0.2rem;
}

.uploadThumbnail_btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.2rem;
    width: 100%;
    height: 100%;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 6px;
    color: #888888;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
}

.addStyle_thumb:hover {
    text-decoration: underline;
    cursor: pointer;
}

.uploadThumbnail_removeBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    width: 70%;
    height: 100%;
    color: #888888;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
}

.uploadThumbnail_removeBtn img {
    height: 100%;
    width: 45px;
    object-fit: cover;
    border-radius: 5px;
}

.addStyle_remove {
    color: #4946C3;
}

.addStyle_remove:hover {
    text-decoration: underline;
    cursor: pointer;
}

.adminTable_imageAndName {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.775rem;
    font-weight: 600;
    font-family: 'Open Sans';
}

.adminTable_actions-view {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #4946C3;
    cursor: pointer;
}

.adminTable_actions-common:enabled {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #4946C3;
    cursor: pointer;
    border: none;
    background: white;
    font-weight: 600;

}

.adminTable_actions-common:enabled:hover {
    text-decoration: underline;
}

.adminTable_actions-common:disabled {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    color: #aaaaaa;
    border: none;
    background: white;
    font-weight: 600;
}

.at_actions-cmnSty {
    margin-right: 10px;
}

.update-asset-btn-admin {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.2rem;
    width: 100%;
    height: 100%;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 6px;
    color: #888888;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
}

.update-asset-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 6px 10px;
    color: #888888;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
}

.inputFieldsContainer_cmnSty {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.premiumTagButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
    height: 35px;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 6px;
    color: #888888;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;

}

.popupVis-imgTagContainer {
    position: relative;
    margin-top: 3rem;
    border-radius: 10px;
}

.popupVis-imgTagContainer video {
    border-radius: 10px;
}

.keywordsAddStyle {
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 6px 10px;
    font-family: Open Sans;
    color: #888888;
    outline: none;
    width: 100%;
    resize: none;
    height: 10rem;
}

.css-jtlhu6-MuiTablePagination-root:last-child {
    display: flex;
}

.popup-updatePrevAndPremiumTag,
.popup-uploadThumbAndUpdateAsset {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    height: 35px;
    width: 100%;
}

.update-asset-btn-admin svg,
.uploadThumbnail_btn svg {
    font-size: 1.2rem;
}

.popup-inputKeyword-sty {
    height: 6rem;
    width: 100%;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 6px 10px;
    font-family: 'Open Sans', sans-serif;
    color: #888888;
    outline: none;
}

.pm-data {
    flex-direction: column;
    display: flex;
    margin: 1.5rem 0rem;
    gap: 0.4rem;
}

.pm-data-headAndField-cmnSty {
    display: grid;
    grid-template-columns: 1fr 2fr 3.5fr 3.5fr;
    align-items: center;
    padding: 0.3rem;
    border: 0.5px solid #c5c5c5;
    border-radius: 10px;
}

.pm-head-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
}

.pm-data-field{
    display: flex;
    font-size: 0.85rem;
    font-weight: 500;
    justify-content: center;
    align-items: center;
}

.pm-data-input{
    padding: 5px;
    height: 2rem;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pm-data-inputAddSty{
    margin-right: 0.5rem;
}