.cartConfirmation {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.cartConfirmation-message {
    display: flex;
    align-items: center;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    color: #666666;
    margin-bottom: 1.5rem;
}

.cartConfirmation-heading{
    margin-bottom: 1rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.cartCofirmation-contentAndDownload{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60%;
    min-height: 370px;
    max-height: 700px;
    border: 1px solid #D9DBE0;
    overflow: hidden;
    padding: 15px 30px;
}

.cartConfirmation-content{
    display: flex;
    flex-direction: column;
    height: 260px;
    overflow: hidden;
    overflow-y: scroll;
}

.cartConfirmation-content::-webkit-scrollbar{
    display: none;
}

.cartConfirmation-downloadAllBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45%;
    background: #ED652B;
    color: #ffffff;
    border: 1px solid #ED652B;
    border-radius: 5px;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}