.home-container {
    height: fit-content;
    width: 100%;
    position: relative;
}

.home-banner-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.home-pageContent {
    margin: 3rem 0rem;
}

.home-menu-items {
    color: #ffffff;
    text-decoration: none;
    text-shadow: 2px 2px 2px #444444;
    font-weight: 500;
}

.home-SearchBar {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: #d9dbe0;
    margin-top: 20px;
    border-radius: 5px;
    min-height: 1.25rem;
    max-height: 2.8rem;
}

.homeHeaderHeight {
    height: 85vh;
}

@media (min-width : 481px) and (max-width : 768px){
    .home-container{
        height: 380px;
    }

    .home-SearchBar {
        margin-top: 0.6rem;
        width: 100%;
        height: 2.5rem;
    }

    .home-pageContent{
        margin: 0rem;
    }

    .homeHeaderHeight{
        height: 331px;
    }
}

@media (min-width : 769px) and (max-width : 909px){
    .navbar{
        height: 3.5rem;
    }

    .home-container{
        height: 440px;
    }

    .home-SearchBar {
        margin-top: 0.6rem;
        width: 100%;
        height: 2.5rem;
    }

    .home-pageContent{
        margin: 0rem;
    }

    .homeHeaderHeight{
        height: 331px;
    }
}

@media (min-width : 910px) and (max-width : 1024px){
    .home-SearchBar {
        max-height: 2.6rem;
    }

    .home-pageContent {
        margin: 2rem 0rem;
    }
}

@media (min-width : 320px) and (max-width : 465px){
    .homeHeaderHeight{
        height: 50vh;
    }

    .home-pageContent{
        margin: 2rem 0rem;
    }
}