.userManagement {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    position: fixed;
}

.userManagement-navbar {
    height: 3rem;
    z-index: 1300;
}

.userManagement-sidebarAndData {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.userManagement-sidebar {
    width: 200px;
}

.userManagement-data {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 2rem 2rem 1rem;
    width: 100%;
}

.userManagement-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 26px;
    font-weight: 600;
    color: #262626;
    margin-bottom: 0.5rem;
}

.userManagement-search{
    margin: 1.5rem 0rem;
}

.userManagement-searchTextfield{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D9D9D9;
    width: 470px;
}

.userManagement-searchTextfield input{
    height: 35px;
    width: 100%;
    border: none;
    outline: none;
    padding-left: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: grey;
}

.userManagement-searchBtn{
    display: flex;
    height: 35px;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #D9D9D9;
    cursor: pointer;
    outline: none;
    background: white;
    border-right: none;
    border-top: none;
    border-bottom: none;
}

.userManagement-userDetails{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.userManagement-userDetails_heading{
    font-family: 'Open Sans';
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
}

.um-userDetailsContainer-cmnSty{
    width: 70%;
    display: grid;
    grid-template-columns: 0rem 3fr 7fr 0rem;
    margin-left: 2rem;
}

.um-udc-headingCmnSty{
    display: flex;
    grid-column: 2/3;
    font-size: 1rem;
    font-weight: 500;
}