.musicPage-banner {
    height: fit-content;
    width: 100%;
    position: relative;
}

.videoPage-banner-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

.musicPage-menu-items {
    color: #ffffff;
    text-decoration: none;
}

.videoPage-SearchBar {
    display: flex;
    align-items: center;
    width: 85%;
    min-width: 400px;
    max-width: 100%;
    height: 45px;
    background-color: #d9dbe0;
    margin-top: 20px;
    border-radius: 5px;
}

.videoPageHeaderHeight {
    height: 65vh;
}

@media (min-width : 481px) and (max-width : 800px) {
    .videoPage-SearchBar {
        margin-top: 0.6rem;
        height: 2.2rem;
        width: 90%;
    }
}

@media (min-width : 801px) and (max-width : 909px) {
    .videoPage-SearchBar {
        margin-top: 0.6rem;
        height: 2.5rem;
        width: 75%;
    }
}

@media (min-width : 320px) and (max-width : 465px) {
    .videoPageHeaderHeight {
      height: 45vh;
    }
  
    .videoPage-SearchBar {
      min-width: 200px;
      width: 100%;
      height: 2.5rem;
    }
  }