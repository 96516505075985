.toSub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    margin-top: 0.6rem;
}

.toSub-iconMsg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    gap: 0.3rem;
    font-size: 14px;
    font-weight: 400;
}

.toSub-im-msg {
    color: #666666;
}

.toSub-im-link {
    cursor: pointer;
    color: #4787E6;
}

.toSub-im-link:hover {
    text-decoration: underline;
}

.toSub-data-submitForm {
    display: grid;
    width: 100%;
    height: 25rem;
    grid-template-columns: 0.6fr 0.4fr;
    border: 1px solid #D9DBE0;
}

.toSub-data {
    width: 100%;
    overflow-x: auto;
    border-right: 1px solid #D9DBE0;
    padding: 0.5rem 0rem 0.5rem 0.5rem;
}

.toSub-data::-webkit-scrollbar {
    display: none;
}

.tsd-img-container {
    display: flex;
    flex-wrap: wrap;
}

.tsd-img-container .image-wrapper {
    position: relative;
    width: 23%;
    height: 135px;
    margin-bottom: 2%;
    margin-right: 2%;
}

.tsd-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.overlay-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
    color: #000;
}

.tsf-filePreview img {
    width: 20%;
}

.toSub-submitForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: scroll;
    scroll-behavior: auto;
    grid-column: 2/3;
    padding: 0.5rem;
}

.tsf-fileName {
    display: flex;
    width: 100%;
    font-size: 0.9rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    margin-top: 0.1rem;
    margin-bottom: 1.3rem;
}

.tsf-filePreview {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tsf-filePreview .tsf-preview-image {
    object-fit: cover;
    width: 65%;
    height: 100%;
    border-radius: 10px;
}


.tsf-fileType-usage {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    width: 100%;
    margin-bottom: 1.3rem;
}

.tsf-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.tsf-form-labelCmnSty {
    color: #383838;
    font-family: Open Sans, sans-serif;
    font-size: 13px;
    font-weight: 600;
}

.tsf-labelField-cmnSty {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
}

.tsf-form-input {
    border: 1px solid #c8c7c7;
    border-radius: 5px;
    color: #888;
    font-family: Open Sans, sans-serif;
    height: 35px;
    outline: none;
    padding: 6px 10px;
    width: 100%;
}


.tsf-form-textArea {
    border: 1px solid #c8c7c7;
    border-radius: 5px;
    color: #888;
    font-family: Open Sans, sans-serif;
    outline: none;
    padding: 6px 10px;
    resize: none;
    width: 100%;
    height: 100px;
}

.tsf-upload-releaseForm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    height: 2.4rem;
    outline: none;
    padding: 0 10px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    color: #888;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
}

.tsf-upload-releaseForm:hover {
    text-decoration: underline;
}

.tsf-form-subBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ed652b;
    border: 1px solid #ed652b;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    height: 35px;
    width: 100%;
    font-size: 0.85rem;
    font-weight: 600;
    gap: 0.5rem;
    margin-top: 1rem;
    outline: none;
}

.tsf-fileType {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-column: 1/2;
}

.tsf-fileUsage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-column: 2/3;
}

.tsf-ft-headCmnSty {
    color: #383838;
    font-family: Open Sans, sans-serif;
    font-size: 13px;
    font-weight: 600;
}

.tsf-ft-labelCmnSty{
    color: #383838;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    font-weight: 500;
}

.tsf-ft-selectionCmnSty{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 1rem;
}