.becomeASeller {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 3rem;
    background-image: linear-gradient(to right top, #57257d, #531a5d, #471342, #37102b, #240d19, #1c0912, #120409, #000000, #000000, #000000, #000000, #000000);
    border: 0.5px solid #000000;
    border-radius: 10px;
    height: auto;
    padding: 1rem;
    margin-bottom: 6rem;
    color: #ffffff;
}

.becomeASeller-companyLogo{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.becomeASeller-companyLogo img{
    height: 2.5rem;
}

.becomeASeller-allContentContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.becomeASeller-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    gap: 0.5rem;
    padding: 1rem 0.7rem;
    border: 1px solid #663091
}

.becomeASeller-details-heading {
    font-size: 17px;
    font-weight: 600;
    color: #F4BD22;
}

.becomeASeller-sb-cmnSty{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.becomeASeller-spanContainer-cmnSty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1px;
}

.becomeASeller-spanContainer-cmnSty span{
    font-size: 13px;
}

.becomeASeller-buyerSpanContainer-cmnSty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.becomeASeller-buyerSpanContainer-cmnSty span{
    font-size: 13px;
}

.becomeASeller-details-heading2 {
    font-size: 18px;
    font-weight: 700;
}

.becomeASeller-details-subHeading {
    font-size: 14px;
    text-align: center;
}

.becomeASeller-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    width: 170px;
    font-size: 14px;
    border: none;
    border: 1px solid #ED652B;
    border-radius: 10px;
    background: #ED652B;
    color: #fff;
    float: right;
}

.becomeASeller-button:hover {
    text-decoration: underline;
}

.becomeASeller-wcContribute {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.7rem;
    border: 1px solid #663091;
}

.becomeASeller-borderRight{
    border-right: 0.5px solid #663091;
}

.becomeASeller-sellersBuyers-cmnSty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.becomeASeller-wcc-table{
    display: grid;
    grid-template-columns: 5fr 5fr;
}

.becomeASeller-wcc-table{
    width: 90%;
    border: 0.5px solid #000;
    border-bottom: none;
}

.becomeASeller-wcc-table-column{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.becomeASeller-wcc-table-column span{
    border-bottom: 0.5px solid #000;
    width: 100%;
    padding: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Open Sans',sans-serif;
}

.bas-table-addSty{
    border-right: 0.5px solid #000;
}

.becomeASeller-wcc-btnContainer{
    width: 100%;
}

/* @media (min-width : 481px) and (max-width : 767px) {
    .becomeASeller {
        padding: 0rem;
        margin: 0.5rem 2rem 3rem 2rem;
    }

    .becomeASeller-details {
        padding: 1rem;
        gap: 0.75rem;
    }

    .becomeASeller-details-heading {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .becomeASeller-details-subHeading {
        font-size: 0.8rem;
    }

    .becomeASeller-button {
        height: 33px;
        width: 135px;
        font-size: 0.8rem;
    }

    .becomeASeller-musicVideo-container img {
        height: 35px;
    }

    .becomeASeller-images-container-image img {
        height: auto;
        width: 142%;
        object-fit: cover;
        border-radius: 0.5rem;
    }

    .becomeASeller-imageVector-container img {
        height: 35px;
    }

    .becomeASeller-imageVector-container-img1 {
        bottom: 95%;
        left: 90%;
    }

    .becomeASeller-imageVector-container-img2 {
        top: 86%;
        left: 80%;
    }
}

@media (min-width : 767px) and (max-width : 869px) {
    .becomeASeller {
        padding-top: 0.5rem;
        margin: 0.5rem 1.5rem 3rem 1.5rem;
    }

    .becomeASeller-details {
        padding: 1rem;
        gap: 0.75rem;
    }

    .becomeASeller-details-heading {
        font-size: 1.4rem;
        font-weight: 700;
    }

    .becomeASeller-details-subHeading {
        font-size: 0.75rem;
    }

    .becomeASeller-button {
        height: 33px;
        width: 135px;
        font-size: 0.78rem;
    }

    .becomeASeller-musicVideo-container img {
        height: 35px;
    }

    .becomeASeller-images-container-image img {
        height: auto;
        width: 142%;
        object-fit: cover;
        border-radius: 0.5rem;
    }

    .becomeASeller-imageVector-container img {
        height: 35px;
    }

    .becomeASeller-imageVector-container-img1 {
        bottom: 115%;
        left: 105%;
    }

    .becomeASeller-imageVector-container-img2 {
        top: 105%;
        left: 95%;
    }


}

@media (min-width : 870px) and (max-width : 909px) {
    .becomeASeller {
        padding-top: 1rem;
        margin: 0.5rem 2rem 3rem 2rem;
    }

    .becomeASeller-details {
        padding: 1rem;
        gap: 0.75rem;
    }

    .becomeASeller-details-heading {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .becomeASeller-details-subHeading {
        font-size: 0.8rem;
    }

    .becomeASeller-button {
        height: 33px;
        width: 135px;
        font-size: 0.8rem;
    }

    .becomeASeller-musicVideo-container img {
        height: 35px;
    }

    .becomeASeller-images-container-image img {
        height: auto;
        width: 142%;
        object-fit: cover;
        border-radius: 0.5rem;
    }

    .becomeASeller-imageVector-container img {
        height: 35px;
    }

    .becomeASeller-imageVector-container-img1 {
        bottom: 135%;
        left: 120%;
    }

    .becomeASeller-imageVector-container-img2 {
        top: 125%;
        left: 120%;
    }
}

@media (min-width : 910px) and (max-width : 1024px) {
    .becomeASeller {
        margin: 0.5rem 2.5rem;
    }

    .becomeASeller-details {
        padding: 1rem 0.5rem 1.5rem 0.7rem;
        gap: 1rem;
    }

    .becomeASeller-details-heading {
        font-size: 1.5rem;
        font-weight: 700;
    }

    .becomeASeller-details-subHeading {
        font-size: 13px;
    }

    .becomeASeller-button {
        font-size: 14px;
    }

    .becomeASeller-musicVideo-container img {
        height: 35px;
    }

    .becomeASeller-imageVector-container img {
        height: 35px;
    }

    .becomeASeller-images-container-image img {
        height: auto;
        width: 12rem;
    }

    .becomeASeller-imageVector-container-img1 {
        bottom: 130%;
        right: 55%;
    }

    .becomeASeller-imageVector-container-img2 {
        top: 115%;
        right: 55%;
    }
}

@media (min-width : 320px) and (max-width : 465px) {
    .becomeASeller {
        display: flex;
        margin: 0rem 1rem;
        margin-bottom: 3rem;
        padding: 0rem;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        padding: 0.5rem 1rem;
    }

    .becomeASeller-details {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: 0.5rem;
    }

    .becomeASeller-details-heading {
        font-size: 1.2rem;
        font-weight: 700;
    }

    .becomeASeller-details-subHeading {
        font-size: 12px;
    }

    .becomeASeller-button {
        height: 32px;
        width: 110px;
        font-size: 12px;
        border-radius: 6px;
    }

    .becomeASeller-images-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .becomeASeller-imageVector-container {
        display: none;
    }

    .becomeASeller-musicVideo-container img {
        height: 0px;
    }

    .becomeASeller-imageVector-container img {
        height: 0px;
    }

    .becomeASeller-musicVideo-container {
        display: none;
    }
} */