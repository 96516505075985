.history-container {
    display: flex;
    flex-direction: column;
    margin: 0rem 3rem;
}

.history-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    font-weight: 700;
    color: #666666;
    margin: 2rem 0rem;
}

.history-contentTable {
    display: flex;
    flex-direction: column;
    width: 75%;
    min-height: fit-content;
    max-height: 440px;
    border: 1px solid #D9DBE0;
    background: #ffffff;
}

.history-contentTable-header {
    display: grid;
    grid-template-columns: 0px 2.5fr 4.5fr 1.3fr 1.7fr 0px;
    padding: 15px 20px;
    border-bottom: 1px solid #D9DBE0;
}

.history-contentTable-headerDate {
    display: flex;
    grid-column: 2/3;
}

.history-contentTable-headerDescription {
    display: flex;
    grid-column: 3/4;
}

.history-contentTable-headerTotal {
    display: flex;
    grid-column: 4/5;
}

.history-contentTable-headerReceipt {
    display: flex;
    grid-column: 5/6;
}

.historyHeader-headingCommonSty {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #888888;
}

.history-contentTable-data {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    gap: 6px;
    background: #ffffff;
}

.history-contentTable-data::-webkit-scrollbar {
    display: none;
}

.history-contentTable-dataFields {
    display: grid;
    grid-template-columns: 0px 2.5fr 4.5fr 1.3fr 1.7fr 0px;
    padding: 15px 20px;
    background: #F5F5F5;
}

.history-contentTable-dataFields-noData {
    padding: 15px 20px;
    background: #F5F5F5;
}

.history-contentTable-dataFields-date {
    display: flex;
    grid-column: 2/3;
}

.history-contentTable-dataFields-subs {
    display: flex;
    grid-column: 3/4;
}

.history-contentTable-dataFields-price {
    display: flex;
    grid-column: 4/5;
}

.history-contentTable-dataFields-receipt {
    display: flex;
    grid-column: 5/6;
}

.history-dataDate {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #666666;
}

.history-dataCommonSty {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #666666;
}

.history-downloadSty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #4946C3;
    cursor: pointer;
}

@media (min-width : 481px) and (max-width : 767px) {
    .history-container {
        margin: 0rem 1.5rem;
    }

    .history-contentTable {
        width: 80%;
    }

    .history-heading {
        font-size: 14px;
        font-weight: 700;
        margin: 1rem 0rem;
    }

    .history-contentTable-header {
        grid-template-columns: 0px 2fr 4.2fr 1.3fr 2.5fr 0px;
        padding: 10px 20px;
    }

    .historyHeader-headingCommonSty {
        font-size: 12px;
    }

    .history-contentTable-data {
        gap: 3px;
    }

    .history-contentTable-dataFields {
        grid-template-columns: 0px 2fr 4.2fr 1.3fr 2.5fr 0px;
        padding: 10px 20px;
    }

    .history-dataDate {
        font-size: 11px;
    }

    .history-dataCommonSty {
        font-size: 11px;
    }

    .history-downloadSty {
        font-size: 11px;
    }

    .history-downloadSty svg{
        font-size: 0.9rem;
    }
}