.cartCheckout{
    display: grid;
    grid-template-columns: 0px 7fr 3fr 0px;
}

.cartCheckout-content{
    display: flex;
    grid-column: 2/3;
    flex-direction: column;
}

.cartCheckout-content{
    display: flex;
    flex-direction: column;
    margin-right: 140px;
    overflow: hidden;
    height: 410px;
    overflow-y: scroll;
    width: 97%;
}

.cartCheckout-content::-webkit-scrollbar{
    display: none;
}

.cartCheckout-Heading-commonSty{
    margin-bottom: 2rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.cartCheckout-billingInputFields{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.cartCheckout-inputCommonStyles{
    display: flex;
    align-items: center;
    font-weight: 600;
    height: 45px;
    width: 100%;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 10px 15px;
    font-family: 'Open Sans';
    font-size: 14px;
    outline: none;
}

.cartCheckout-billinParallelFields-commonSty{
    display: flex;
    flex-direction: row;
}

.checkoutInputAddSty{
    margin-right: 2rem;
}

.checkoutHeadingAddSty{
    margin-top: 5rem;
}

.cartCheckout-paymentInputFields{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.paymentOptionPopup {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.paymentOptionPopup-headingAndClose {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.paymentOptionPopup-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.paymentOptionPopup-close {
    cursor: pointer;
}

.paymentOptionPopup-optionBtn {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.paymentOptionPopup-optionBtn button{
    width: fit-content;
    height: 2rem;
    padding: 1.3rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ed652b;
    border: none;
    outline: none;
    color: white;
    font-weight: 500;
    border-radius: 0.3rem;
    cursor: pointer;
}

.paymentOptionPopup-optionBtn span{
    font-weight: 500;
    font-size: 0.8rem;
}

@media (min-width : 481px) and (max-width : 768px){
    .cartCheckout {
        display: grid;
        grid-template-columns: 0px 7fr 3fr 0px;
        height: 70vh;
    }

    .cartCheckout-content{
        height: 100%;
    }

    .cartCheckout-Heading-commonSty {
        margin-bottom: 0.5rem;
    }

    .cartCheckout-billingInputFields {
        gap: 15px;
    }

    .cartCheckout-inputCommonStyles {
        height: 40px;
    }

    .checkoutInputAddSty {
        margin-right: 1rem;
    }
}


@media (min-width : 320px) and (max-width : 465px){
    .cartCheckout {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .cartCheckout-Heading-commonSty{
        margin-bottom: 1rem;
        font-size: 14px;
    }

    
}