.resetPassword {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: grid;
    grid-template-columns: .5fr .5fr;
}

.resetPassword-sideImage-tile {
    display: grid;
    grid-column: 1/2;
    height: 100vh;
}

.resetPassword-sideImage-tile img {
    height: 100vh;
    width: 50vw;
}

.resetPassword-form {
    display: flex;
    grid-column: 2/3;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px 26px 10px 26px;
}

.resetPassword-heading-subHeading {
    font-size: 12px;
    color: #888888;
    width: 50%;
    text-align: center;
    margin-top: 1rem;
}

.resetPassword-form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #C8C7C7;
    border-radius: 20px;
    padding: 1.5rem 1rem 1rem 1rem;
    width: 100%;
    margin-top: 1rem;
}

.resetPassword-heading {
    font-size: 24px;
}

.resetPassword-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem 0.5rem 1rem;
    width: 100%;
    gap: 10px;
}

.resetPassword-form-emailTextfield {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.resetPassword-input {
    padding: 10px;
    height: 2.4rem;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    outline: none;
}

.resetPassword-text-error {
    height: 10px;
    font-size: 11px;
    color: red;
}

.resetPassword-submit {
    width: 100%;
}

