.adminNavbar-container{
    display: grid;
    height: 100%;
    background: #ffffff;
    grid-template-columns: 3rem 0.5fr 0.5fr 3rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.adminNavbar-container-cLogo{
    grid-column: 2/3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.adminNavbar-container-cLogo img{
    height: 40px;
    cursor: pointer;
}

.adminNavbar-container-buttonAndUserInfo{
    grid-column: 3/4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.adminNavbar-ccAndUpBtn{
    margin-right: 25px;
}

.adminNavbar-ccAndUpBtn button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 140px;
    border: 1px solid #4946C3;
    border-radius: 5px;
    color: #4946C3;
    font-size: 14px;
    font-weight: 500;
    background: #ffffff;
    cursor: pointer;
    outline: none;
}

.adminNabvarUpload{
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
}

.adminNavbar-userInfo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.adminNavbar-userInitials{
    display: flex;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    border: 1px solid #DCDBFF;
    background: #DCDBFF;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    color: #4946C3;
    margin-right: 5px;
    margin-left: 12px;
}

.adminNavbar-userName{
    font-size: 14px;
    font-weight: 600;
    color: #505050;
    margin-right: 3px;
}

.addUser_headingAndCancel{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.addUser_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;
    margin: 0.6rem 1rem;
}

.addUser_labelInputContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.1rem;
    width: 100%;
}

.addUser_inputField{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 1.9rem;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
    outline: none;
    color: #666666;
}

.addUser_btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
}

.addUser_btn button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.3rem;
    width: 8rem;
    font-family: 'Open Sans';
    font-size: 0.8rem;
    font-weight: 500;
    color: #ffffff;
    border-radius: 0.3rem;
    background: #4946C3;
    border: 1px solid #4946C3;
    outline: none;
    cursor: pointer;
}

/* Modal Content Styles */
.upload-modalContent{
    display: grid;
    height: 100%;   
    padding: 10px 5px 0px 5px;
}

.uploadModal-headingAndCross{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.uploadModal-heading{
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    font-weight: 600;
}

.uploadModal-dragAndSelectBtn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.uploadModal-dragAndSelectBtn span{
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 12px;
}

.uploadModal-dragAndSelectBtn button{
    height: 32px;
    width: 220px;
    border: 1px solid #4946C3;
    border-radius: 5px;
    background: #4946C3;
    color: #ffffff;
    cursor: pointer;
    outline: none;
}

.uploadModal-tosAndCgAndLm{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.uploadModal-p{
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    padding-left: 9px;
}

.uploadModal-pLink{
    color:#4787E6;
    cursor: pointer;
}

.cc-dropdowns{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin-top: 2rem;
    width: 100%;
}

.cc-dropdowns_roleAndUserCmnSty{
    width: 100%;
}

.cc-table{
    margin-top: 2rem;
}

.addUser-text-error{
    height: 10px;
    font-size: 11px;
    color: red  ;
}