.adminSettings {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}

.adminSettings-navbar {
    height: 3rem;
    position: fixed;
    width: 100%;
    z-index: 1300;
}

.adminSettings-sidebarAndData {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.adminSettings-sidebar {
    width: 200px;
}

.adminSettings-data {
    display: flex;
    flex-direction: column;
    margin: 3rem 2rem 2rem 1rem;
    width: 100%;
}

.asd-heading {
    font-family: Open Sans;
    font-size: 26px;
    font-weight: 600;
    color: #262626;
    margin-bottom: 0.5rem;
}

.asd-allSettings-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem;
    gap: 3.5rem;
}

.asd-allSetting-ccms {
    border: 1px solid #D9DBE0;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    height: fit-content;
    gap: 1rem;
}

.asd-ccms-heading {
    font-size: 1rem;
    font-weight: 600;
    text-decoration: underline;
    font-family: 'Open Sans', sans-serif;
}

.asd-ccms-settingsCmnSty {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #4946C3;
    padding: 1rem;
    border-radius: 10px;
    background: #efefef;
    gap: 0.5rem;
}

.asd-acc-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    text-decoration: underline;
}

.asd-acc-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.asd-acc-fieldsContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.asd-acc-labelAndField {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    width: 70%;
}

.asd-acc-searchLabelAndField {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    width: 40%;
}

.asd-acc-labelAndField-label {
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.3rem;
}

.asd-acc-labelAndField-input {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    height: 2rem;
    outline: none;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding-left: 14px;
    color: #888888;
    font-size: 0.8rem;
}

.asd-acc-labelAndField-textArea{
    display: flex;
    flex-direction: row;
    background: #ffffff;
    height: 5rem;
    outline: none;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 5px;
    color: #888888;
    font-size: 0.8rem;
    resize: none;
}

.asd-acc-btn-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 15%;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #FFFFFF;
    font-weight: 500;
    background: #4946C3;
    border: 1px solid #4946C3;
    border-radius: 5px;
    cursor: pointer;
}

.asd-acc-btn {
    display: flex;
    flex-direction: row;
    width: 85%;
    align-items: center;
    justify-content: flex-end;
}

.ccms-search-contentCategory {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.ccms-searchResult-table {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #000;
    border-radius: 5px;
}

.ccms-searchResult-tableHead {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    border-bottom: 0.5px solid #000;
}

.ccms-sr-col {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 0.5px solid #000;
    padding: 0.7rem 0rem;
}

.ccms-sr-dataFont {
    font-size: 0.9rem;
    font-weight: 500;
}

.ccms-sr-headFont {
    font-size: 0.85rem;
    font-weight: 400;
}

.asd-acc-searchResult-heading {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    font-weight: 400;
}

.asd-acc-searchResult-heading span {
    font-size: 0.95rem;
    font-weight: 600;
}

.ccms-no-data {
    font-size: 0.95rem;
    font-weight: 500;
    padding: 1rem 0rem;
}

.asd-acc-tooltipBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    border-radius: 100%;
    padding: 0.1rem;
    border: 1px solid #4946C3;
    color: #4946C3;
    font-weight: 600;
    cursor: pointer;
}

.asd-acc-tooltip-title{
    display: flex;
    flex-direction: column;
}