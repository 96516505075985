.pictureTile{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    width: 50%;
    position: fixed;
}

.pictureTile-row1{
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0px 4px;
}

.pictureTile-row1-img1{
    display: flex;
    height: 25vh;
}

.pictureTile-row1-img2{
    display: flex;
    height: 30vh;
}
.pictureTile-row1-img3{
    display: flex;
    height: 30vh;
}
.pictureTile-row1-img4{
    display: flex;
    height: 15vh;
}

.pictureTile-row2{
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0px 4px;
}
.pictureTile-row3{
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0px 4px;
}

@media (min-width : 320px) and (max-width : 465px){
    .pictureTile{
        display: none;
    }
}