.account-container{
    display: flex;
    flex-direction: column;
    margin: 0rem 3rem;
    overflow: hidden;
}

.account-heading{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #666666;
    margin: 1rem 0rem;
}

.account-profilePicture{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 1rem;
}

.account-profilePicture button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #C8C8C8;
    border-radius: 5px;
    background: #ffffff;
    height: 30px;
    width: fit-content;
    padding: 8px 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #262626;
}

.account-profilePicture-name{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    background: #DCDBFF;
    border-radius: 50%;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #4946C3;
}

.account-profileInfo{
    display: flex;
    flex-direction: column;
    border: 1px solid #D9DBE0;
    width: 70%;
}

.account-profile-fieldsCommonSty{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1.9rem;
    height: 45px;
    border-bottom: 1px solid #D9DBE0;
}

.account-profile-password{
    display: grid;
    grid-template-columns: 0px 3fr 5fr 2fr 0px;
    padding: 15px 30px;
}

.account-profile-fieldNameCommonSty{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #666666;
    width: 100%;
}

.account-profile-fieldDataCommonSty{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #262626;
    width: 100%;
}

.account-profile-editCommonSty{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.account-profile-editCommonSty span{
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #4946C3;
    cursor: pointer;
    border: none;
    background: #ffffff;
}

.account-signInAccDetails{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    color: #666666;
}

.account-profile-editableFieldsCommonSty{
    display: grid;
    grid-template-columns: 0px 3fr 5fr 2fr 0px;
    padding: 8px 30px;
    border-bottom: 1px solid #D9DBE0;
    align-items: center;
}

.account-profile-inputAndEditCommonSty{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.account-profile-inputAndCancel-commonSty{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.account-editableInputFields{
    display: flex;
    align-items: center;
    padding: 0px 7px;
    outline: none;
    border: 1px solid #D9DBE0;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #262626;
    width: 100%;
    height: 35px;
}

.crossButtonSpan{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ED652B;
    border-radius: 50%;
    font-size: 13px;
    margin-left: 10px;
    color: #ED652B;
    cursor: pointer;
}

.account-editableInputFields:disabled{
    border: none;
}

.account-pupdAndAccDetails{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin-top: 1rem;
}

.account-pupdAndAccDetails button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.3rem;
    width: 8rem;
    font-family: 'Open Sans';
    font-size: 0.8rem;
    font-weight: 500;
    color: #ffffff;
    border-radius: 0.3rem;
    background: #ED652B;
    border: 1px solid #ED652B;
    outline: none;
    cursor: pointer;
}

.account-profile-emptyDiv{
    width: 100%;
}

@media (min-width : 481px) and (max-width : 768px){
    .account-container {
        margin: 0rem 2rem;
    }

    .account-heading {
        font-size: 14px;
        font-weight: 700;
        margin: 0.7rem 0rem;
    }

    .account-profilePicture-name {
        height: 35px;
        width: 35px;
        font-size: 14px;
    }

    .account-profilePicture {
        margin-bottom: 0.7rem;
    }

    .account-profile-fieldsCommonSty {
        padding: 0rem 1.5rem;
        height: 38px;
    }

    .account-profile-fieldNameCommonSty {
        font-size: 11px;
    }

    .account-profile-fieldDataCommonSty {
        font-size: 11px;
    }

    .account-profile-editCommonSty span {
        font-size: 10px;
    }

    .account-pupdAndAccDetails {
        margin-top: 0.5rem;
    }

    .account-signInAccDetails {
        font-size: 11px;
    }

    .account-pupdAndAccDetails button {
        height: 2rem;
        width: 5rem;
        font-size: 0.7rem;
    }

    .account-editableInputFields {
        font-size: 12px;
        height: 26px;
    }

    .crossButtonSpan {
        font-size: 10px;
        margin-left: 5px;
    }
}

@media (min-width : 320px) and (max-width : 465px){
    .account-container {
        padding: 0rem 1rem 5rem 1rem;
        margin: 0;
    }

    .account-heading {
        font-size: 15px;
        margin: 1rem 0rem;
    }

    .account-profilePicture-name {
        height: 40px;
        width: 40px;
        font-size: 15px;
    }

    .account-profileInfo {
        width: 100%;
    }

    .account-profile-fieldsCommonSty {
        padding: 0rem 0.7rem;
        height: 40px;
    }

    .account-profile-fieldNameCommonSty {
        font-size: 12px;
    }

    .account-profile-fieldDataCommonSty {
        font-size: 12px;
    }

    .account-profile-emptyDiv{
        width: 0;
    }

    .account-profile-editCommonSty span {
        font-size: 11px;
    }

    .account-pupdAndAccDetails {
        width: 100%;
        margin-top: 0.5rem;
        align-items: flex-start;
    }

    .account-signInAccDetails {
        font-size: 11px;
    }

    .account-pupdAndAccDetails button {
        height: 1.9rem;
        width: 5rem;
        font-size: 12px;
    }

    .account-editableInputFields {
        font-size: 12px;
        height: 30px;
    }
}