.regis-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}

.regis-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ed652b;
    border: 1px solid #ed652b;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    height: 35px;
    width: 100%;
    font-size: 0.85rem;
    font-weight: 600;
    gap: 0.5rem;
    margin-top: 1rem;
    outline: none;
}

.regis-button:hover {
    text-decoration: underline;
}

.regis-link {
    font-size: 0.8rem;
}

.regis-link span {
    color: #00e;
    font-weight: 600;
}

.regis-link span:hover {
    cursor: pointer;
    text-decoration: underline;
}

.regisIndividual-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
}

.regisIndividual-form-firstLastName {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    width: 100%;
}

.regin-form-input {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    height: 2.4rem;
    outline: none;
    padding: 0 10px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    color: #888;
}

.regin-form-address {
    border: 1px solid #c8c7c7;
    border-radius: 5px;
    color: #888;
    font-family: 'Open Sans', sans-serif;
    outline: none;
    padding: 6px 10px;
    resize: none;
    width: 100%;
    height: 10vh;
}

.regisIndividual-form-aadhaar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    height: 2.4rem;
    outline: none;
    padding: 0 10px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    color: #888;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
}

.regisIndividual-form-aadhaar label {
    cursor: pointer;
}

.regisIndividual-form-aadhaar:hover {
    cursor: pointer;
    text-decoration: underline;
}

.password-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    height: 2.4rem;
    outline: none;
    padding: 0 10px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    color: #888;
}

.seller-regis {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.seller-regis-welcomeMsg {
    font-family: 'Open Sans';
    font-size: 19px;
    font-weight: 600;
}

.seller-regis-msg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    margin: 2rem 0rem;
}

.seller-regis-confirmMsg {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}

.seller-highlight-words {
    font-weight: 500;
    color: rgb(255, 0, 111);
}

.seller-highlight-msg {
    font-weight: 500;
    color: red;
    font-size: 12px;
}

.seller-regis-confirmMsg2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.2rem;
    margin: 1.5rem 0rem;
}

.srcm-highlithing-email {
    font-size: 15px;
    font-weight: 600;
}

.seller-regis-sendAgain-continueShopping {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 1rem;
    align-items: center;
}

.seller-regis-sendAgain {
    display: flex;
    flex-direction: column;
}

.seller-regis-sendAgain label {
    font-size: 14px;
    font-weight: 500;
}

.seller-regis-sendAgain button {
    border: none;
    outline: none;
    display: flex;
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
    color: #1a0dab;
    font-weight: 600;
    margin-top: 0.1rem;
}

.seller-regis-continueShopping button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 140px;
    border: 1px solid #4946C3;
    border-radius: 5px;
    color: #4946C3;
    font-size: 14px;
    font-weight: 500;
    background: #ffffff;
    cursor: pointer;
    outline: none;
}