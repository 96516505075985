.viewCart {
    display: grid;
    grid-template-columns: 0px 7.3fr 2.7fr 0px;
}

.viewCart-content {
    display: flex;
    grid-column: 2/3;
    flex-direction: column;
}

.viewCart-headingsCommonSty {
    margin-bottom: 12px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.viewCart-content-items {
    display: flex;
    flex-direction: column;
    margin-right: 25px;
    overflow: hidden;
    height: 410px;
    overflow-y: scroll;
}

.viewCart-content-items::-webkit-scrollbar {
    display: none;
}

.viewCart-content-itemDetails {
    display: grid;
    grid-template-columns: 0px 2fr 5.5fr 2.5fr 0px;
    border: 1px solid #D9DBE0;
    border-radius: 3px;
    height: 130px;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
}

.viewCart-itemDetails-image {
    display: flex;
    grid-column: 2/3;
    overflow: hidden;
    padding-right: 10px;
}

.viewCart-itemDetails-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.viewCart-itemDetails-info {
    display: flex;
    grid-column: 3/4;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
}

.viewCart-itemDetails-nameFormat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}

.viewCart-itemDetails-name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.viewCart-itemDetails-name p {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #666666;
}

.viewCart-itemDetails-format {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #666666;
}

.viewCart-itemDetails-ResoLice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.viewCart-itemDetails-Reso {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 3px;
}

.viewCart-itemDetails-Lice {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-start;
    gap: 3px;
}

.viewCart-itemDetails-headCommonSty {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #666666;
}

.viewCart-itemDetails-headDataCommonSty {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}

.viewCart-itemDetails-priceAndRemove {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.viewCart-itemDetails-price {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #666666;
}

.viewCart-itemDetails-removeBtn {
    border: none;
    outline: none;
    background: #ffffff;
    color: #4946C3;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    cursor: pointer;
}

.viewCart-noDataDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 55vh;
    margin-left: 22rem;
}

@media (min-width : 481px) and (max-width : 768px) {
    .viewCart {
        display: grid;
        grid-template-columns: 0px 7fr 3fr 0px;
    }

    .viewCart-headingsCommonSty {
        margin-bottom: 0.7rem;
    }

    .viewCart-content-items {
        margin-right: 8px;
        height: 65vh;
    }

    .viewCart-content-itemDetails {
        grid-template-columns: 0px 3fr 5fr 2fr 0px;
        height: 90px;
        width: 100%;
        padding: 5px;
        margin-bottom: 8px;
    }

    .viewCart-itemDetails-nameFormat {
        gap: 0px;
    }

    .viewCart-itemDetails-image {
        padding-right: 0px;
    }

    .viewCart-itemDetails-info {
        margin-left: 6px;
    }

    .viewCart-itemDetails-name {
        gap: 2px;
    }

    .viewCart-itemDetails-name p {
        font-size: 0.7rem;
        font-weight: 600;
    }

    .viewCart-itemDetails-name svg {
        font-size: 0.8rem;
    }

    .viewCart-itemDetails-format {
        font-size: 10px;
    }

    .viewCart-itemDetails-Reso {
        gap: 0px;
    }

    .viewCart-itemDetails-headCommonSty {
        font-size: 10px;
    }

    .viewCart-itemDetails-headDataCommonSty {
        font-size: 12px;
    }

    .viewCart-itemDetails-Lice {
        gap: 0px;
    }

    .viewCart-itemDetails-price {
        font-size: 12px;
    }

    .viewCart-itemDetails-removeBtn {
        font-size: 11px;
    }

    .viewCart-orderDetails-name {
        gap: 3px;
    }

    .viewCart-orderDetails-name svg {
        font-size: 0.8rem;
    }

    .viewCart-orderDetails-name p {
        font-size: 0.7rem;
        font-weight: 600;
    }

    .viewCart-orderDetails-planAndPrice {
        padding: 3px;
        border-radius: 4px;
    }

    .viewCart-orderDetails-planAndPrice p {
        font-size: 10px;
    }

    .viewCart-orderTotalAmount p {
        font-size: 10px;
    }

    .viewCart-orderSummaryContainer-orderDetails {
        gap: 2px;
        margin-bottom: 5px;
    }

    .viewCart-orderTotalAmount {
        margin: 6px 0px;
    }

    .viewCartPtc-button {
        height: 30px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 4px;
        margin-bottom: 3px;
        cursor: pointer;
    }

    .viewCartCs-button button {
        font-size: 10px;
    }
}

@media (min-width : 320px) and (max-width : 465px) {
    .viewCart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .viewCart-headingsCommonSty {
        margin-bottom: 4px;
        font-size: 14px;
    }

    .viewCart-content-items {
        margin-right: 0px;
        overflow: hidden;
        height: fit-content;
    }

    .viewCart-content-itemDetails {
        grid-template-columns: 0px 3fr 5fr 2fr 0px;
        border-radius: 8px;
        height: 85px;
        padding: 4px;
        margin-bottom: 8px;
    }

    .viewCart-itemDetails-image {
        grid-column: 2/3;
        padding-right: 0px;
    }

    .viewCart-itemDetails-info {
        margin-left: 5px;
    }

    .viewCart-itemDetails-name {
        gap: 4px;
    }

    .viewCart-itemDetails-name svg {
        height: 0.9em;
        width: 0.9em;
    }

    .viewCart-itemDetails-name p {
        font-size: 12px;
        font-weight: 700;
    }

    .viewCart-itemDetails-format {
        font-size: 11px;
    }

    .viewCart-itemDetails-Reso {
        gap: 0px;
    }

    .viewCart-itemDetails-headCommonSty {
        font-size: 11px;
    }

    .viewCart-itemDetails-headDataCommonSty {
        font-size: 12px;
    }

    .viewCart-itemDetails-Lice {
        gap: 0px;
    }

    .viewCart-itemDetails-price {
        font-size: 13px;
    }

    .viewCart-itemDetails-removeBtn {
        font-size: 12px;
    }

    .viewCart-noDataDiv {
        min-height: 50vh;
        margin-left: 0rem;
    }

    .viewCart-content {
        width: 100%;
    }

    .viewCart-orderSummary {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
    }

    .viewCart-orderSummaryContainer {
        border-radius: 7px;
        padding: 4px;
        width: 100%;
    }

    .viewCart-orderDetails-name{
        gap: 4px;
    }

    .viewCart-orderDetails-name svg{
        height: 0.9em;
        width: 0.9em;
    }
    
    .viewCart-orderDetails-name p{
        font-size: 12px;
    }

    .viewCart-orderDetails-planAndPrice {
        padding: 5px;
    }

    .viewCart-orderDetails-planAndPrice p {
        font-size: 12px;
    }

    .viewCart-orderTotalAmount p {
        font-size: 12px;
    }

    .viewCartPtc-button {
        height: 40px;
        font-size: 13px;
        margin-bottom: 8px;
    }
}