.signUp {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.signUp-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 10px 16px 10px 16px;
}

.signUp-heading {
    font-size: 1.5rem;
    font-weight: 800;
}

.signUp-form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #C8C7C7;
    border-radius: 20px;
    padding: 1rem 1rem 0rem 1rem;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 3rem);
    overflow-y: scroll;
}

.signUp-form-content::-webkit-scrollbar{
    display: none;
}

.signUp-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    font-size: 12px;
}

.signUp-form-redirection {
    margin-left: 3px;
    color: #4946C3;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
}

.signUp-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem 0.5rem 1rem;
    width: 100%;
    gap: 10px;
}

.signUp-input-initial {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.signUp-input {
    padding: 0px 10px;
    height: 2.4rem;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    outline: none;
    width: 100%;
}

.signUp-input-initial-firstName {
    width: 48%;
}

.signUp-input-initial-lastName {
    width: 48%;
}

.signUp-text-error {
    height: 10px;
    font-size: 11px;
    color: red;
}

.signUp-form-textField {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.signUp-submit {
    width: 100%;
    height: 35px;
}

.signUp-wrapper {
    display: grid;
    grid-template-columns: 46% 8% 46%;
    width: 100%;
    padding: 0.8rem 1rem;
    align-items: center;
}

.signUp-line {
    height: 1px;
    background: #D9D9D9;
}

.signUp-line-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888888;
    font-size: 12px;
}

.signUp-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0rem 1rem;
}

.signUp-btn-container button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 2.4rem;
    background: #F5F5F5;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    margin: 10px 0px;
    cursor: pointer;
}

.signUp-footer {
    font-size: 12px;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 2px;
}

.signUp-footer span {
    font-size: 13px;
    color: #4946C3;
    cursor: pointer;
}

.signUp_success_msgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    margin: 2rem 0rem;
    border: 1px solid #C8C7C7;
    border-radius: 0.6rem;
    padding: 1rem;
    width: 40%;
}

.signUp_success_msgContainer button {
    color: #4946C3;
    border: 0rem;
    background: #ffffff;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
}

.signUp_success_msgContainer button:hover {
    text-decoration: underline;
}

.signUp_success_heading {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
}

.signUp_success_msg {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 500;
}

.signUp_success_support {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.support_heading {
    font-size: 0.85rem;
}

.support_emailId {
    font-size: 0.9rem;
    font-weight: 600;
}

.signUp-policies {
    display: flex;
    flex-direction: row;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.8rem;
    margin: 0.2rem 0rem 0.5rem 0rem;
}

.signup-policy-links {
    color: #4946C3;
    cursor: pointer;
    font-weight: 500;
}

.signup-policy-links:hover {
    text-decoration: underline;
}

.signUp-company-logo {
    height: 3rem;
    cursor: pointer;
}

.inputCompanyAddSty {
    margin-bottom: 0.6rem;
}

@media (min-width : 481px) and (max-width : 909px) {
    .signUp-form {
        padding: 5px 8px 5px 8px;
    }

    .signUp-company-logo {
        height: 2rem;
    }

    .signUp-form-content {
        border-radius: 10px;
        padding: 0.5rem 0.5rem 0rem 0.5rem;
    }

    .signUp-heading {
        font-size: 1.1rem;
        font-weight: 700;
    }

    .signUp-text {
        margin-top: 0.5rem;
        font-size: 11px;
    }

    .signUp-form-container {
        padding: 0.75rem 0.5rem 0.25rem 0.5rem;
        gap: 2px;
    }

    .signUp-input-initial-firstName {
        width: 49%;
    }

    .signUp-input-initial-lastName {
        width: 49%;
    }

    .signUp-input {
        padding: 0px 5px;
        height: 2rem;
        border-radius: 5px;
    }

    .signUp-text-error {
        height: 10px;
        font-size: 0.5rem;
        color: red;
    }

    .inputCompanyAddSty {
        margin-bottom: 0.5rem;
    }

    .signUp-password-addSty {
        height: 2.5rem;
    }

    .signUp-submit {
        height: 2rem;
    }

    .signUp-wrapper {
        padding: 0.4rem 0.5rem 0.5rem 0.5rem;
    }

    .signUp-btn-container button {
        margin: 0px 0px 0.6rem 0px;
    }

    .signUp-policies {
        font-size: 0.7rem;
        margin: 0.5rem 0rem 0.5rem 0rem;
    }

    .signUp_success_msgContainer {
        margin: 1rem 0rem;
    }

    .signUp_success_heading {
        font-size: 1.1rem;
    }

    .signUp_success_msg {
        font-size: 0.9rem;
    }

    .signUp_success_msgContainer button {
        font-size: 0.8rem;
    }
}

@media only screen and (min-width: 1000px) and (max-width :1249px) {
    .signUp-form {
        padding: 10px 26px 10px 26px;
    }

    .signUp-form-content {
        padding: 0.5rem 1rem 0 1rem;
    }

    .signUp-heading {
        font-size: 22px;
    }

    .signUp-text {
        margin-top: 6px;
    }

    .signUp-form-container {
        padding: 1rem 1rem 0rem 1rem;
    }

    .signUp-input-initial-firstName input {
        height: 2.2rem;
    }

    .signUp-input-initial-lastName input {
        height: 2.2rem;
    }

    .signUp-form-textField input {
        height: 2.2rem;
    }

    .signUp-submit {
        height: 2.2rem;
    }

    .signUp-wrapper {
        padding: 0.5rem 1rem;
    }

    .signUp-text-error {
        height: 8px;
        font-size: 10px;
    }

    .signUp-footer {
        margin-bottom: 6px;
    }

    .signUp-footer span {
        font-size: 11px;
    }
}

@media only screen and (min-width: 1250px) and (max-width :1400px) {
    .signUp-form-content {
        padding: 0.8rem 1rem 0rem 1rem;
    }

    .signUp-heading {
        font-size: 23px;
    }

    .signUp-text {
        margin-top: 0.8rem;
    }

    .signUp-form-container {
        padding: 1.2rem 1rem 0rem 1rem;
    }
}

@media only screen and (min-width: 375px) and (max-width :425px) {
    .signUp {
        height: 100%;
        display: flex;
        overflow: scroll;
    }

    .signUp-company-logo {
        height: 2.3rem;
    }

    .signUp-form-content {
        border-radius: 12px;
        padding: 0.8rem;
        width: 100%;
        height: fit-content;
        max-height: fit-content;
        overflow-y: visible;
        margin-bottom: 2rem;
    }

    .signUp-heading {
        font-size: 18px;
        font-weight: 700;
    }

    .signUp-text {
        margin-top: 0.5rem;
        font-size: 11px;
    }

    .signUp-form-redirection {
        font-weight: 600;
        font-size: 12px;
    }

    .signUp-form-container {
        padding: 1rem 0rem 0.5rem 0rem;
        gap: 10px;
    }

    .signUp-input-initial-firstName {
        width: 49%;
    }

    .signUp-input-initial-lastName {
        width: 49%;
    }

    .signUp-input {
        height: 2.2rem;
        font-size: 0.8rem;
    }

    .signUp-submit {
        font-size: 0.8rem;
    }

    .signUp-wrapper {
        padding: 0.8rem 0rem;
    }

    .signUp-btn-container {
        padding: 0rem;
    }

    .signUp-policies {
        font-size: 11px;
    }

    .signup-policy-links {
        font-size: 12px;
    }
}