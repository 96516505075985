.sellerNavbar-container{
    display: grid;
    height: 100%;
    background: #ffffff;
    grid-template-columns: 3rem 0.5fr 0.5fr 3rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.sellerNavbar-container-cLogo{
    grid-column: 2/3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.sellerNavbar-container-cLogo img{
    height: 40px;
    cursor: pointer;
}

.sellerNavbar-container-buttonAndUserInfo{
    grid-column: 3/4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.sellerNavbar-ccAndUpBtn{
    margin-right: 25px;
}

.sellerNavbar-ccAndUpBtn button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 140px;
    border: 1px solid #4946C3;
    border-radius: 5px;
    color: #4946C3;
    font-size: 14px;
    font-weight: 500;
    background: #ffffff;
    cursor: pointer;
    outline: none;
}

.adminNabvarUpload{
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
}

.sellerNavbar-userInfo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.sellerNavbar-userInitials{
    display: flex;
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    border: 1px solid #DCDBFF;
    background: #DCDBFF;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    color: #4946C3;
    margin-right: 5px;
    margin-left: 12px;
}

.sellerNavbar-userName{
    font-size: 14px;
    font-weight: 600;
    color: #505050;
    margin-right: 3px;
}