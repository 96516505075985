.byMonth {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.8rem;
}

.bym-head {
    margin-top: 0.8rem;
    font-size: 1.1rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.bym-filtersExp-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.bym-filters {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.bym-exportCsv {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D9D9D9;
    padding: 0rem 1.5rem;
    border-radius: 5px;
    color: #888888;
    font-size: 13px;
    height: 35px;
    width: 120px;
    cursor: pointer;
}

.bym-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9D9D9;
    height: 100%;
    max-height: 300px;
    min-height: 300px;
    border-radius: 5px;
}

.bym-msg {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #666666;
}