.LicAg{
    background: #efefef;
    color: #626262;
    font-family: 'Merriweather Sans', sans-serif;
}

.licAg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 3rem 5rem 3rem;  
    font-size: 14px;
}

.fs-22 {
    font-size: 1.3rem;
}

.fs-20 {
    font-size: 1.2rem;
}

.cmn-Mrg{
    margin-bottom: 0.5rem;
}

.mg-rgt{
    margin-right: 1rem;
}

.mrg-tp{
    margin-top: 0.5rem;
}

.mg_btm_one{
    margin-bottom: 1rem;
}

.mg_btm_two{
    margin-top: 1rem;
}

.licAg-contentContainer-CommonSty{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.licAg-contentCommonSty {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    justify-content: center;
    align-items: flex-start;
}

.licAg-inspireContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.licAg-hyperlink{
    color: #0000EE;
    text-decoration: underline;
    cursor: pointer;
}

.licAg_content_commonSty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1.8rem;
    width: 100%;
}

.licAg_content_infoCmnSty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
}

.licAg_index_info{
    display: flex;
    flex-direction: row;
}

.licAg_infoContainer{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.licAg-info-points{
    display: flex;
    flex-direction: column;
}

.flexrowCmnSty{
    display: flex;
    flex-direction: row;
}

.flex-columnCmnSty{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
