.srp-container {
    padding: 0rem 3rem;
}

.srp-searchbar-styles {
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    margin-top: 20px;
    border: 1px solid #D9DBE0;
    border-radius: 5px;
}

.srp-container-heading {
    display: flex;
    flex-direction: row;
    padding: 1.5rem 0rem;
}

.srp-container-otherOptions {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.srp-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: 1px solid #D9DBE0;
    border-radius: 4px;
    margin-left: 1rem;
}

.srp-option img {
    margin-right: 8px;
    height: 14px;
    width: 13px;
}

.srp-container-nextFunc{
    display: grid;
    grid-template-columns: 3rem .535fr .455fr 3rem;
    margin-bottom: 4rem;
}

.srp-nextBtn{
    display: flex;
    grid-column: 2/3;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.srp-nextBtn-button{
    height: 38px;
    width: 91px;
    padding: 10px;
    background: #ED652B;
    border: 1px solid #ED652B;
    color: #ffffff;
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
}

.srp-pageNavigation{
    display: flex;
    grid-column: 3/4;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.srp-pageNavigation1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 45px;
    border: 1px solid #888888;
    border-radius: 5px;
    margin-right: 10px;
    color: #666666;
}

.srp-pageNavigation-count{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #444444;
    margin-right: 10px;
}

.srp-pageNavigation-prv{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    background: #ED652B;
    color: #ffffff;
    border-radius: 8px;
    margin-right: 5px;
    cursor: pointer;
}

.srp-pageNavigation-nxt{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    background: #ED652B;
    color: #ffffff;
    border-radius: 8px;
    cursor: pointer;
}


