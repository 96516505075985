.pc-container {
    margin: 2rem 3rem 0rem 3rem;
}

.pc-keywordsAndHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.pc-heading {
    font-size: 18px;
    font-weight: 700;
    padding: 0rem 1rem 0.2rem 1rem;
    border-bottom: 2px solid #ED652B;
}

.pc-keywords-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: min-content;
  gap: 10px;
  margin-top: 2rem;
  width: 100%;
}

.pc-keyword-style {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 4px;
    border: 0.5px solid black;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 500;
  }

.pc-keyword-style:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border: 0.5px solid #ED652B;  
    background-color: #efefef;  
}