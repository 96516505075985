.byProduct {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.8rem;
}

.byp-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9D9D9;
    height: 100%;
    max-height: 375px;
    min-height: 375px;
    border-radius: 5px;
    margin-top: 2rem;
}

.byp-msg {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #666666;
}