.forgotPassword {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.forgotPassword-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px 26px 10px 26px;
}

.forgotPassword-heading-subHeading{
    font-size: 12px;
    color: #888888;
    width: 50%;
    text-align: center;
    margin-top: 1rem;
}

.forgotPassword-form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #C8C7C7;
    border-radius: 20px;
    padding: 1.5rem 1rem 1rem 1rem;
    width: 100%;
    margin-top: 1rem;
}

.forgotPassword-heading{
    font-size: 24px;
}

.forgotPassword-form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem 0.5rem 1rem;
    width: 100%;
    gap: 10px;
}

.forgotPassword-form-emailTextfield{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.forgotPassword-input {
    padding: 10px;
    height: 2.4rem;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    outline: none;
}

.forgotPassword-text-error {
    height: 10px;
    font-size: 11px;
    color: red  ;
}

.forgotPassword-submit-button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.forgotPassword-submit{
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgotPassword-backOption{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    margin-bottom: 1rem;
}

.forgotPassword-backOption span{
    color: #4946C3;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline;
}

.forgotPassword-success-msg{
    font-family: 'Open Sans';
    font-size: 1rem;
}

.forgotPassword-spam-msg{
    font-family: 'Open Sans';
    font-size: 0.9rem;
}

.forgotPassword-msg-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.forgotPassword-msg-container button{
    color: #4946C3;
    border: 0rem;
    background: #ffffff;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
}

.forgotPassword-msg-container button:hover{
    text-decoration: underline;
}

.forgotPassword-form-support{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.1rem;
}

.forgotPassword-companyLogo{
    height: 3rem;
    cursor: pointer;
}

@media (min-width : 481px) and (max-width : 909px){
    .forgotPassword-companyLogo{
        height: 2rem;
    }

    .forgotPassword-form {
        padding: 10px 1rem 10px 1rem;
    }

    .forgotPassword-success-msg {
        font-size: 0.9rem;
    }

    .forgotPassword-spam-msg {
        font-size: 0.8rem;
    }

    .forgotPassword-msg-container button {
        font-size: 0.85rem;
    }
}

@media (min-width : 320px) and (max-width : 465px){
    .forgotPassword {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100vw;
        display: flex;
    }

    .forgotPassword-form {
        padding: 1rem;
    }

    .forgotPassword-companyLogo {
        height: 2.3rem;
    }

    .forgotPassword-form-content {
        margin-top: 0.5rem;
        border-radius: 12px;
        padding: 0.8rem;
    }

    .forgotPassword-heading-subHeading {
        width: 100%;
        margin-top: 0.7rem;
    }

    .forgotPassword-form-container {
        padding: 1rem 0rem 0.5rem 0rem;
        width: 100%;
    }

    .forgotPassword-backOption {
        margin-top: 0px;
    }

    .forgotPassword-backOption span {
        font-size: 12px;
    }

    .forgotPassword-submit {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.85rem;
    }
}