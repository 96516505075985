.adminAnalytics{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    position: fixed;
}

.adminAnalytics-navbar{
    height: 3rem;
    z-index: 1300;
}

.adminAnalytics-sidebarAndData{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.adminAnalytics-sidebar{
    width: 200px;
}

.adminAnalytics-data{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 2rem 2rem 1rem;
    width: 100%;
}