.wishlist {
    padding: 0px;
    margin: 0px;
}

.wishlist-container {
    margin: 0rem 3rem;
}

.wishlist-heading {
    margin: 1.5rem 0px 1rem 0px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.wishlist-content {
    display: flex;
    grid-column: 2/3;
    flex-direction: column;
    overflow: hidden;
    height: 460px;
    min-height: 400px;
    max-height: 500px;
    overflow-y: scroll;
}

.wishlist-content::-webkit-scrollbar {
    display: none;
}

.wishlist-headingsCommonSty {
    margin-bottom: 12px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.wishlist-content-items {
    display: flex;
    flex-direction: column;
    margin-right: 25px;
    overflow: hidden;
    height: 410px;
    overflow-y: scroll;
}

.wishlist-content-items::-webkit-scrollbar {
    display: none;
}

.wishlist-content-itemDetails {
    display: grid;
    grid-template-columns: 0px 2fr 5.5fr 2.5fr 0px;
    border: 1px solid #D9DBE0;
    border-radius: 3px;
    height: 130px;
    width: 70%;
    padding: 10px 20px 10px 10px;
    margin-bottom: 15px;
}

.wishlist-itemDetails-image {
    display: flex;
    grid-column: 2/3;
    overflow: hidden;
    padding-right: 10px;
}

.wishlist-itemDetails-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.wishlist-itemDetails-info {
    display: flex;
    grid-column: 3/4;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
}

.wishlist-itemDetails-nameFormat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
}

.wishlist-itemDetails-name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.wishlist-itemDetails-name p {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #666666;
}

.wishlist-itemDetails-format {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #666666;
}

.wishlist-itemDetails-ResoLice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.wishlist-itemDetails-Reso {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 3px;
}

.wishlist-itemDetails-Lice {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-start;
    gap: 3px;
}

.wishlist-itemDetails-headCommonSty {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #666666;
}

.wishlist-itemDetails-headDataCommonSty {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}

.wishlist-itemDetails-priceAndRemove {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.wishlist-itemDetails-view {
    background: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #666666;
    border: none;
    outline: none;
    cursor: pointer;
}

.wishlist-itemDetails-removeBtn {
    border: none;
    outline: none;
    background: #ffffff;
    color: #4946C3;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    cursor: pointer;
}

.wishlist-noDataDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 55vh;
}

@media (min-width : 481px) and (max-width : 767px) {
    .wishlist-heading {
        margin: 1rem 0px 0.6rem 0px;
        font-size: 13px;
    }

    .wishlist-content-itemDetails {
        grid-template-columns: 0px 3fr 5fr 2fr 0px;
        height: 90px;
        width: 80%;
        padding: 5px;
        margin-bottom: 8px;
    }

    .wishlist-itemDetails-image {
        padding-right: 0px;
    }

    .wishlist-itemDetails-info {
        margin-left: 5px;
    }

    .wishlist-itemDetails-nameFormat {
        gap: 0px;
    }

    .wishlist-itemDetails-name svg {
        font-size: 0.8rem;
    }

    .wishlist-itemDetails-name {
        gap: 2px;
    }

    .wishlist-itemDetails-name p {
        font-size: 0.7rem;
    }

    .wishlist-itemDetails-format {
        font-size: 10px;
    }

    .wishlist-itemDetails-Reso {
        gap: 0px;
    }

    .wishlist-itemDetails-headCommonSty {
        font-size: 10px;
    }

    .wishlist-itemDetails-headDataCommonSty {
        font-size: 12px;
    }

    .wishlist-itemDetails-Lice {
        gap: 0px;
    }

    .wishlist-itemDetails-view {
        font-size: 12px;
    }

    .wishlist-itemDetails-removeBtn {
        font-size: 11px;
    }

    .wishlist-content {
        height: 100%;
        min-height: 365px;
        max-height: 500px;
    }
}
