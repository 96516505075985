.jcp-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}

.jcp-button-link {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.jcp-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ed652b;
    border: 1px solid #ed652b;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    height: 35px;
    width: 100%;
    font-size: 0.85rem;
    font-weight: 600;
    gap: 0.5rem;
}

.jcp-button:hover{
    text-decoration: underline;
}

.jcp-link{
    font-size: 0.8rem;
}

.jcp-link span{
    color: #00e;
    font-weight: 600;
}

.jcp-link span:hover{
    cursor: pointer;
    text-decoration: underline;
}