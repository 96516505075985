.phoneInput {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    height: 2.4rem;
    outline: none;
    padding: 0 10px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    color: #888;
}

.phoneInput-select{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.2rem;
    border: none;
    outline: none;
    font-size: 0.8rem;
    width: 35%;
    color: #888;
}

.phoneInput-input{
    font-family: 'Open Sans', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.2rem;
    border: none;
    outline: none;
    font-size: 0.8rem;
    width: 86%;
    padding-left: 1rem;
    color: #888;
}