.adminContent{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

.adminContent-navbar{
    height: 3rem;
    z-index: 1300;
    position: fixed;
    width: 100%;
}

.adminContent-sidebarAndData{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.adminContent-sidebar{
    width: 200px;
}

.adminContent-data{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 2rem 2rem 1rem;
    width: 100%;
}

.adminContent-heading{
    font-family: 'Open Sans', sans-serif;
    font-size: 26px;
    font-weight: 600;
    color: #262626;
    margin-bottom: 0.5rem;
}

.adminContent-headingAndSearch{
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}

.adminContent-heading span{
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    color: #262626;
}

.adminContent-searchImpExp{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    height: 36px;
}

.adminContent-searchFilterBtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.adminContent-searchTextfield{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D9D9D9;
    width: 470px;
}

.adminContent-searchTextfield input{
    height: 35px;
    width: 100%;
    border: none;
    outline: none;
    padding-left: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: grey;
}

.adminContent-searchBtn{
    display: flex;
    height: 35px;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #D9D9D9;
    cursor: pointer;
}

.adminContent-filterBtn{
    display: flex;
    height: 36px;
    width: 40px;
    justify-content: center;
    align-items: center;
    border: 1px solid #D9D9D9;
    margin-left: 13px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.adminContent-impExtBtn{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 36px;
}

.adminContent-import{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid #D9D9D9;
    padding: 0rem 1.5rem;
    border-radius: 5px;
    color: #626262;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
}
.adminContent-export{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid #D9D9D9;
    padding: 0rem 1.5rem;
    border-radius: 5px;
    color: #626262;
    font-size: 14px;
    font-weight: 500;
}

.adminContent-filterPopup{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.adminContent-filterPopup::-webkit-scrollbar{
    display: none;
}

.filterPopup-categoryInput{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.filterPopup-categoryInput span{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.filterPopup-priceRange{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}

.filterPopup-priceRange-heading{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    margin-top: 7px;
}

.filterPopup-dateRange-heading{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.filterPopup-priceRange-valueSlider{
    display: flex;
    flex-direction: column;
    width: 340px;
    height: fit-content;
    margin-bottom: 20px;
}

.filterPopup-priceRange-value{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filterPopup-priceRange-valueCommonSty{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.priceRangeHeading{
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    margin-right: 8px;
}

.priceRangeValue{
    display: flex;
    height: 35px;
    width: 80px;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    align-items: center;
    padding-left: 11px;
    outline: none;
}

.minMax_inputField{
    display: flex;
    height: 35px;
    width: 80px;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    align-items: center;
    padding-left: 11px;
    outline: none;
}

.minMax_inputField::-webkit-outer-spin-button,
.minMax_inputField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.minMax_inputField[type=number] {
  -moz-appearance: textfield;
}

.filterPopup-dateRange{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filterPopup-datePicker{
    display: flex;
    gap: 10px;
    width: 340px;
}

.adminDatePicker{
    margin-right: 10px;
    width: 100%;
}

.filterPopup-cancelApplyBtn{
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.filterPopup-cancel{
    font-family: 'Open Sans', sans-serif;
    width: 100px;
    height: 35px;
    border: 1px solid #F1F1F1;
    cursor: pointer;
    border-radius: 5px;
    color: #444444;
    background: #ffffff;
    cursor: pointer;
}

.filterPopup-apply{
    font-family: 'Open Sans', sans-serif;
    width: 100px;
    height: 35px;
    border: 1px solid #4946C3;
    border-radius: 5px;
    color: #ffffff;
    background: #4946C3;
    margin-left: 10px;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.filterPopup-nameCommonSty{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filterPopup-headingCommonSty{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
}

.filterPopup-nameCommonSty input{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 35px;
    width: 340px;
    color: #888888;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    padding-left: 10px;
    font-weight: 400;
    outline:'none';
}

.adminContent-filterSearchOptions{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 35px;
    margin-top: 1rem;
    visibility: hidden;
}

.searchOptions-commonSty{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    height: 100%;
    padding: 0px 10px;
    border-radius: 5px;
    background: #F5F5F5;
    width: fit-content;
}

.searchOptions-nameCommonSty{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #444444;
    font-weight: 400;
}

.adminContent-clear{
    padding: 7px 10px;
    margin-left: 15px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: #626262;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    height: 36px;
    background: white;
}

.adminContent-clear-disabled{
    padding: 7px 10px;
    margin-left: 15px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: #626262;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
    height: 36px;
    opacity: 0.7;
}

.filterPopup-priceRange-Slider{
    margin-right: 0.5rem;
}
