.imageGallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.3rem;
    margin-top: 1rem;
    border-radius: 10px;
}

.imageGallery-tile {
    width: 100%;
    height: 0;
    padding-bottom: 65%;
    position: relative;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.imageGallery-tile video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;
}

.imageGallery-ndf {
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    padding: 5px;
    font-weight: 500;
}

@media (min-width : 481px) and (max-width : 909px) {
    .imageGallery {
        margin-top: 0.5rem;
        border-radius: 5px;
    }

    .imageGallery-ndf {
        font-size: 0.8rem;
        font-weight: 600;
    }
}

@media (min-width : 910px) and (max-width : 1024px) {
    .imageGallery-ndf {
        font-size: 0.9rem;
    }

    .imageGallery {
        margin-top: 0.7rem;
        border-radius: 5px;
    }
}

@media (min-width : 320px) and (max-width : 465px){
    .imageGallery{
    grid-template-columns: repeat(2,1fr);
    grid-gap: 0.2rem;
    margin-top: 0.5rem;
    border-radius: 5px;
    }

    .imageGallery-tile{
        border-radius: 5px;
    }

    .imageGallery-ndf{
        font-size: 0.8rem;
    }
}