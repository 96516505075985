.table {
    width: 100%;
    margin: 2rem 0rem;
    font-family: 'Open Sans', sans-serif;
}

.table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #dcdbff;
    border-radius: 0.5rem;
}

.table-head {
    width: 100%;
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(5, 1fr);
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 0.82rem;
    padding: 1rem;
}

.table-data {
    width: 100%;
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(5, 1fr);
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.4rem 1rem;
}

.table-data::selection {
    background: yellow;
}

.table-cell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.table-cell-radioBtn {
    display: grid;
    grid-template-columns: 1fr 0fr;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.table-cell-discount {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.table-cell-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.table-cell-tagPrice {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.table-rows-addStyle {
    border-bottom: 0.5px solid black;
}

.table-instructionsAndBtn {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.table-instruction {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 0.75rem;
    font-weight: 600;
}

.table-btn {
    background: #ED652B;
    color: #ffffff;
    width: 103px;
    height: 35px;
    float: left;
    font-weight: 600;
    border: 1px solid #ED652B;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}