.dDModal{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.dDModal-headingAndClose{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.dDModal-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.dDModal-close{
    cursor: pointer;
}

.dDModal-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.dDModal-buttons button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 110px;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #FFFFFF;
    font-weight: 500;
    background: #4946C3;
    border: 1px solid #4946C3;
    border-radius: 5px;
    cursor: pointer;
}