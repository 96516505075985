.exclusivePage-banner {
    height: fit-content;
    width: 100%;
    position: relative;
}

.exclusive-banner-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.exclusivePageHeaderHeading {
    height: 45px;
    width: 350px;
    margin-bottom: 10px;
}

.exclusivePage-menu-items {
    color: #ffffff;
    text-decoration: none;
}

.exclusivePage-SearchBar {
    display: flex;
    align-items: center;
    width: 85%;
    min-width: 400px;
    max-width: 100%;
    height: 45px;
    background-color: #d9dbe0;
    margin-top: 20px;
    border-radius: 5px;
}

.exclusivePage-pageContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 3rem;
}

.exclusivePage-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 27px;
    font-weight: 700;
}

.exclusivePageHeaderHeight {
    height: 65vh;
}

/* Default styles for the image gallery */
.exclusivePage-imageGallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 10px;
    margin: 1rem 0rem 2rem 0rem;
}

.exclusivePage-imageGallery-image {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
    cursor: pointer;
}

.exc-ht{
    font-size: 1.2em;
    font-weight: 700;
}

.exc-cs{
    font-size: 1.2rem;
    font-weight: 600;
    color: #ED652B;
}

.exc-support{
    font-size: 0.8rem;
    font-weight: 500;
}

.exc-support span{
    font-size: 0.85rem;
    color: red;
}

.exc-homeBtn{
    cursor: pointer;
    background: #ED652B;
    font-size: 0.8em;
    padding: 0.8em 2em;
    color: #ffffff;
    border: none;
    outline: none;
    border-radius: 1em;
    font-weight: 500;
}

.exc-homeBtn:hover{
    text-decoration: underline;
}

/* Styles for screens that are 768px or smaller */
@media (max-width: 768px) {
    .exclusivePage-imageGallery {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
    }
}

/* Styles for screens that are 480px or smaller */
@media (max-width: 480px) {
    .exclusivePage-imageGallery {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(9, 1fr);
    }
}



@media (min-width : 481px) and (max-width : 800px) {
    .exclusivePage-SearchBar {
        margin-top: 0.6rem;
        height: 2.2rem;
        width: 90%;
    }

    .exclusivePage-pageContent {
        padding: 3rem 0rem;
        font-size: 1.5rem;
    }
}

@media (min-width : 801px) and (max-width : 909px) {
    .exclusivePage-SearchBar {
        margin-top: 0.6rem;
        height: 2.5rem;
        width: 75%;
    }

    .exclusivePage-pageContent {
        padding: 3rem 0rem;
        font-size: 1.5rem;
    }
}

@media (min-width : 320px) and (max-width : 465px) {
    .exclusivePageHeaderHeight {
        height: 45vh;
    }

    .exclusivePage-SearchBar {
        min-width: 200px;
        width: 100%;
        height: 2.5rem;
    }

    .exclusivePage-pageContent {
        padding: 3rem 0rem;
        font-size: 20px;
        margin: 0rem 1rem;
    }
}