.login {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 10px 16px 10px 16px;
    max-height: 100vh;
    width: 40%
}

.login-form-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #C8C7C7;
    border-radius: 20px;
    padding: 1rem 1rem 0rem 1rem;
    width: 100%;
    max-height: 100vh;
    height: calc(100vh - 4rem);
}

.login-heading {
    font-size: 24px;
    font-weight: 800;
}

.login-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    font-size: 12px;
}

.login-form-redirection {
    margin-left: 3px;
    color: #4946C3;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 1rem 0.5rem 1rem;
    width: 100%;
    gap: 10px;
}

.login-form-emailTextField {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.5rem;
}

.login-form-passwordTextField {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-input {
    padding: 10px;
    height: 2.4rem;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    outline: none;
}

.login-text-error {
    height: 10px;
    font-size: 11px;
    color: red;
}

.login-submit {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.login-link {
    color: #4946C3;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
}

.login-wrapper {
    display: grid;
    grid-template-columns: 46% 8% 46%;
    width: 100%;
    padding: 0.8rem 1rem;
    align-items: center;
}

.login-line {
    height: 1px;
    background: #D9D9D9;
}

.login-line-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888888;
    font-size: 14px;
}

.login-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0rem 1rem;
}

.login-btn-container button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 2.4rem;
    background: #F5F5F5;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    margin: 10px 0px;
    cursor: pointer;
}

.login-loginWithSso {
    margin-bottom: 1.5rem;
    margin-top: 10px;
    cursor: pointer;
}

.login-companyLogo {
    height: 3rem;
    cursor: pointer;
}

.login-option{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1rem 0rem 1rem
}

@media (min-width : 481px) and (max-width : 909px) {
    .login-form {
        padding: 5px 8px;
    }

    .login-companyLogo {
        height: 2rem;
        margin: 0.5rem 0rem;
    }

    .login-form-content {
        border-radius: 10px;
        padding: 0.5rem 0.5rem 0rem 0.5rem;
    }

    .login-heading {
        font-size: 1.1rem;
        font-weight: 700;
    }

    .login-text {
        margin-top: 0.7rem;
    }

    .login-form-container {
        padding: 0.75rem 0.5rem 0.25rem 0.5rem;
        gap: 2px;
    }

    .login-form-emailTextField {
        margin-bottom: 0.5rem;
    }

    .login-input {
        padding: 10px;
        height: 2rem;
    }

    .login-text-error {
        height: 10px;
        font-size: 0.6rem;
    }

    .login-passwordInp-addSty {
        height: 2rem;
    }

    .login-submit {
        margin-top: 1.5rem;
    }

    .login-link {
        font-size: 0.7rem;
        font-weight: 500;
    }

    .login-btn-container button {
        margin: 0px 0px 0.6rem 0px;
    }
}

@media (min-width : 320px) and (max-width : 465px) {
    .login {
        margin: 0;
        display: flex;
        width: 100vw;
        justify-content: center;
        align-items: center;
        overflow-y: scroll;
    }

    .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .login-companyLogo {
        height: 2.3rem;
        margin-bottom: 0.5rem;
    }

    .login-form-content {
        border-radius: 12px;
        padding: 0.8rem;
        max-height: 100%;
        height: 100%;
        margin-bottom: 2rem;
    }

    .login-heading {
        font-size: 18px;
        font-weight: 700;
    }

    .login-text {
        margin-top: 0.5rem;
        font-size: 11px;
    }

    .login-form-redirection {
        font-size: 12px;
    }

    .login-form-container {
        padding: 1rem 0rem 0.5rem 0rem;
        width: 100%;
        gap: 0rem;
    }

    .login-submit {
        margin-top: 0.7rem;
    }

    .login-link {
        font-size: 0.75rem;
    }

    .login-btn-container div{
        width: 100%;
    }

    .login-btn-container {
        padding: 0rem;
        margin-top: 1rem;
    }
}

.maxDevice {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
}

.maxDevice-heading {
    display: flex;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.3rem;
    font-weight: 700;
    color: #f0ad4e;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.maxDevice-warningMsg {
    color: rgb(255, 0, 0);
}

.maxDevice-emailAndUserId {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.1rem;
}

.maxDevice-emailAndUserCmnSty {
    display: grid;
    grid-template-columns: 0rem 3fr 1fr 6fr 0rem;
}

.emailAndUserHeading_cmcSty {
    display: flex;
    grid-column: 2/3;
    font-size: 0.9rem;
}

.emailAndUserData_cmnSty {
    display: flex;
    grid-column: 4/5;
    font-size: 0.85rem;
    font-weight: 500;
    color: #666;
}

.maxDevice-deviceDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
}

.dotdyali {
    display: flex;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 500;
}

.maxDevice-individualDeviceDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid #F5F5F5;
    background: #dcdbff;
    padding: 0rem 1rem;
}

.maxDevice-individualDetailsCommonSty {
    display: grid;
    grid-template-columns: 0rem 3.5fr 0.2fr 6.3fr 0rem;
    width: 100%;
}

.deviceDetails_headCmnSty {
    display: flex;
    grid-column: 2/3;
    font-size: 0.85rem;
    font-weight: 600;
}

.deviceDetails_dataCmnSty {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.85rem;
    font-weight: 500;
    color: #666666;
    grid-column: 4/5;
    margin-left: 0.5rem;
}

.maxDevice-solutionMsg {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 0.9rem;
    font-weight: 500
}

.maxDevice_closeBtn {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.maxDevice_closeBtn button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 8rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #FFFFFF;
    font-weight: 500;
    background: #4946C3;
    border: 1px solid #4946C3;
    border-radius: 5px;
    cursor: pointer;
}