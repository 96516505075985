.navbar {
    width: 100%;
    height: 4rem;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 3rem 0.8fr 1.8fr 0fr 0.5fr 0.8fr 3rem;
    color: #ffffff;
}

.logo {
    display: grid;
    grid-column: 2/3;
    align-items: center;
    cursor: pointer;
}

.menu-link {
    grid-column: 3/4;
    font-weight: 600;
}

.menu-link ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
}

/* .menu-item{
    color: #ffffff;
    text-decoration: none;
} */

.subscription {
    grid-column: 5/6;
}

.subscription ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
}

.nav-buttons {
    grid-column: 6/7;
    align-items: center;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-buttons-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    width: 100%;
    cursor: pointer;
}

.nav-buttons-cart span {
    font-size: 10px;
    font-weight: 700;
    border: solid #ED652B;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    text-align: center;
    background: #ED652B;
    position: relative;
    justify-content: center;
    align-items: center;
    float: right;
    top: -8px;
    right: 9px;
}

.button {
    height: 39px;
    width: 128px;
    border: 1px solid #ED652B;
    background: #ED652B;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
}

.btn-container {
    display: flex;
    justify-content: flex-end;
}

.hamburger-menu {
    display: none;
}

.nav-profile {
    height: 30px;
    width: 37px;
    background-color: #DCDBFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: #4946C3;
    cursor: pointer;
    text-transform: capitalize;
}

.nav-profile-nameInitials{
    font-size: 15px;
    font-weight: 600;
}

.menu-vectorIcon-sty{
    font-weight: 800;
}

@media (min-width : 481px) and (max-width : 800px) {
    .navbar{
        height: 3rem;
        grid-template-columns: 2rem 0.8fr 1fr 0.3fr 1fr 0.8fr 2rem;
    }
    .menu-link,
    .subscription,
    .nav-buttons {
        display: none;
    }

    .hamburger-menu {
        display: grid;
        grid-column: 6/7;
        text-align: right;
        justify-content: flex-end;
        font-size: 1.2rem;
    }

    .drawer {
        padding: 0.6rem;
        margin: 0rem;
        height: 100%;
        width: 100%;
    }

    .drawer-cart-cancelBtn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
    }

    .drawer-link-container {
        padding: 0rem 1.5rem;
    }

    .drawer-menu-link {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
        gap: 0.3rem;
    }

    .drawer-menu-listItemCmnSty {
        display: flex;
        flex-direction: row;
        padding: 0.3rem 1.5rem;
        align-items: center;
        justify-content: space-between;
        background: #f5f5f5;
        border-radius: 0.4rem;
        cursor: pointer;
    }

    .drawer-menu-listItemCmnSty:hover {
        background: #ED652B;
        color: #e3e2f2;
    }

    .drawer-menu-fontSty {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .menu-vectorIcon-sty {
        width: 10%;
        font-family: 'Open Sans', sans-serif;
        font-weight: 800;
        font-size: 1.3rem;
    }

    .nav-buttons-cart {
        width: fit-content;
    }

    .btn-container {
        padding: 0rem 1.5rem;
        margin: 2rem 0rem;
    }

    .nav-profile {
        margin: 2rem 0rem 2rem 1.5rem;
        height: 30px;
        width: 30px;
        font-size: 0.8rem;
    }

    .button {
        height: 2rem;
    }
    
    .nav-profile-nameInitials {
        font-size: 0.85rem;
        font-weight: 600;
    }
}

@media (min-width : 801px) and (max-width : 909px) {
    .menu-link,
    .subscription,
    .nav-buttons {
        display: none;
    }

    .hamburger-menu {
        display: grid;
        grid-column: 6/7;
        text-align: right;
        justify-content: flex-end;
        font-size: 1.2rem;
    }

    .drawer {
        padding: 0.6rem;
        margin: 0rem;
        height: 100%;
        width: 100%;
    }

    .drawer-cart-cancelBtn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
    }

    .drawer-link-container {
        padding: 0rem 1.5rem;
    }

    .drawer-menu-link {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
        gap: 0.3rem;
    }

    .drawer-menu-listItemCmnSty {
        display: flex;
        flex-direction: row;
        padding: 0.3rem 1.5rem;
        align-items: center;
        justify-content: space-between;
        background: #f5f5f5;
        border-radius: 0.4rem;
        cursor: pointer;
    }

    .drawer-menu-listItemCmnSty:hover {
        background: #ED652B;
        color: #e3e2f2;
    }

    .drawer-menu-fontSty {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .menu-vectorIcon-sty {
        width: 10%;
        font-family: 'Open Sans', sans-serif;
        font-weight: 800;
        font-size: 1.3rem;
    }

    .nav-buttons-cart {
        width: fit-content;
    }

    .btn-container {
        padding: 0rem 1.5rem;
        margin: 2rem 0rem;
    }

    .nav-profile {
        margin: 2rem 0rem 2rem 1.5rem;
        height: 30px;
        width: 30px;
        font-size: 0.8rem;
    }

    .button {
        height: 2rem;
    }
    
    .nav-profile-nameInitials {
        font-size: 0.85rem;
        font-weight: 600;
    }
}

@media (min-width : 910px) and (max-width : 1024px){
    .navbar {
        width: 100%;
        height: 3.5rem;
        grid-template-columns: 2.5rem 0.8fr 1.2fr 0.1fr 1fr 0.8fr 2.5rem;
    }
}

@media (min-width : 320px) and (max-width : 465px){
    .menu-link,
    .subscription,
    .nav-buttons {
        display: none;
    }

    .navbar{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0rem 1rem;
        height: 3rem;
    }

    .hamburger-menu {
        display: block;
        color: #ffffff;
        font-size: 1rem;
    }

    .drawer {
        padding: 0.6rem;
        margin: 0rem;
        height: 100%;
        width: 100%;
    }

    .drawer-cart-cancelBtn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
    }

    .drawer-link-container {
        padding: 0rem 1.5rem;
    }

    .drawer-menu-link {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
        gap: 0.3rem;
    }

    .drawer-menu-listItemCmnSty {
        display: flex;
        flex-direction: row;
        padding: 0.3rem 1.5rem;
        align-items: center;
        justify-content: space-between;
        background: #f5f5f5;
        border-radius: 0.4rem;
        cursor: pointer;
    }

    .drawer-menu-listItemCmnSty:hover {
        background: #ED652B;
        color: #e3e2f2;
    }

    .drawer-menu-fontSty {
        font-family: 'Open Sans', sans-serif;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .nav-buttons-cart{
        width: fit-content;
    }

    .nav-profile {
        height: 37px;
        width: 37px;
    }
}