.orderSummary-menu-items {
    color: black;
    text-decoration: none;
}

.orderSummary-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 3rem 2rem 3rem;
}

.oS-heading{
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: underline;
}

.oS-details{
    display: flex;
    flex-direction: column;
    background: #f0ecec;
    width: 55%;
    margin-top: 2rem;
    gap: 1rem;
    padding: 1rem;
    border-radius: 10px;
}

.oS-details-commonSty{
    display: grid;
    grid-template-columns: 0rem 4fr 6fr 0rem;
}

.oS-details-headStyCmn{
    display: flex;
    grid-column: 2/3;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    font-weight: 500;
}

.oS-details-valueStyCmn{
    display: flex;
    grid-column: 3/4;
    align-items: center;
    justify-content: flex-start;
}

.oS-link-home{
    font-size: 0.9rem;
    color: #00e;
    margin-top: 1rem;
    font-weight: 600;
}

.oS-link-home:hover{
    text-decoration: underline;
}