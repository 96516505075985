.sellerMedia{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    position: fixed;
}

.sellerMedia-navbar{
    height: 3rem;
    z-index: 1300;
}

.sellerMedia-sidebarAndData{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.sellerMedia-sidebar{
    width: 200px;
}

.sellerMedia-data{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 1rem 1rem 1rem;
    width: 100%;
}

.sellerMedia-heading{
    font-family: 'Open Sans', sans-serif;
    font-size: 26px;
    font-weight: 600;
    color: #262626;
    margin-bottom: 0.5rem;
}