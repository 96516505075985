.adminDashboard{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    position: fixed;
}

.adminDashboard-navbar{
    height: 3rem;
    z-index: 1300;
}

.adminDashboard-sidebarAndData{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.adminDashboard-sidebar{
    width: 200px;
}

.adminDashboard-data{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 2rem 2rem 1rem;
    width: 100%;
}

.adminDashboard-heading{
    font-family: 'Open Sans', sans-serif;
    font-size: 26px;
    font-weight: 600;
    color: #262626;
    margin-bottom: 0.5rem;
}

.adminDashboard-downloadsWishlist{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.admDa-commonSty{
    display: flex;
    flex-direction: column;
    border: 1px solid #D9DBE0;
    width: 49%;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
}

.admDa-heading-commonSty{
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    color: #888888;
}

.admDa-count-commonSty{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    color: #262626;
    cursor: pointer;
}

.adminDashboard-publishedUnpublished{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.adminDashboard-mainDivCommonSty{
    display: flex;
    flex-direction: column;
    width: 49%;
    border: 1px solid #D9DBE0;
    background: #ffffff;
    padding: 0rem 1rem 1rem 1rem;
    height: 22rem;
    overflow: hidden;
    overflow-y: scroll;
}

.adminDashboard-mainDivCommonSty::-webkit-scrollbar{
    display: none;
}

.adDash-headingViewAllCommonSty{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0rem;
}

.adDash-headingCommonSty span{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.adDash-viewAllCommonSty{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4946C3;
    cursor: pointer;
}

.adDash-contentCardCommonSty{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: #F5F5F5;
    margin-bottom: 5px;
}

.adDash-contentCardCommonSty img{
    height: 28px;
    width: 28px;
    object-fit: cover;
}

.adDash-contentCardCommonSty p{
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #444444;
    margin-left: 10px;
}

.adDash-contentCard-viewAll{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #888888;
}

.adDash-contentCard-contentCommonSty{
    display: flex;
}

.adminDashboard-penAppRej{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.admDa-penAppRej-cmnSty{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #D9DBE0;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
}

.adminDashboard-headingAndToggle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
}

.adminDashboard-toggle{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.adminDashboard-toggle input{
    height: 2rem;
    width: 8rem;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    padding: 6px 10px;
    font-family: 'Open Sans', sans-serif;
    color: #888888;
    outline: none;
    margin-right: 1rem;
}

.adminDashboard-toggle input::-webkit-outer-spin-button,
.adminDashboard-toggle input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}