.auditLogs {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    position: fixed;
}

.auditLogs-navbar {
    height: 3rem;
    z-index: 1300;
}

.auditLogs-sidebarAndData {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.auditLogs-sidebar {
    width: 200px;
}

.auditLogs-data {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 2rem 2rem 1rem;
    width: 100%;
}

.auditLogs_heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    color: #262626;
}

.auditLogs_table_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.auditLogs_table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #C8C7C7;
    min-height: 29rem;
    max-height: 50rem;
    height: fit-content;
    overflow: hidden;
    border-radius: 5px;
}

.auditLogs_table_rowCommonSty {
    display: grid;
    grid-template-columns: 0px 2.4fr 0.8fr 1.2fr 1.5fr 1.5fr 1.5fr 1.1fr 0px;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    border-bottom: 1px solid #C8C7C7;
}

.headingRow_addSty {
    height: 4rem;
    font-size: 0.9rem;
    font-weight: 600;
}

.dataRow_addSty {
    height: fit-content;
    font-size: 0.8rem;
    font-weight: 500;
}

.addStyle_userId {
    grid-column: 2/3;
    border-right: 1px solid #C8C7C7;
    overflow: hidden;
}

.addStyle_role {
    grid-column: 3/4;
    border-right: 1px solid #C8C7C7;
}

.addStyle_contentId {
    grid-column: 4/5;
    border-right: 1px solid #C8C7C7;
}

.addStyle_actions {
    grid-column: 5/6;
    border-right: 1px solid #C8C7C7;
}

.addStyle_actionType {
    grid-column: 6/7;
    border-right: 1px solid #C8C7C7;
}

.addStyle_fields {
    grid-column: 7/8;
    border-right: 1px solid #C8C7C7;
}

.addStyle_date {
    grid-column: 8/9;
}

.table_headCommonSty {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.auditLogs-searchTextfield {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D9D9D9;
    width: 470px;
}

.auditLogs-searchTextfield input {
    height: 35px;
    width: 100%;
    border: none;
    outline: none;
    padding-left: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: grey;
}

.auditLogs-searchBtn {
    display: flex;
    height: 35px;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #D9D9D9;
    cursor: pointer;
}

.auditLogs-searchBarAndExport{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.auditLogs-export{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid #D9D9D9;
    padding: 0rem 1.5rem;
    border-radius: 5px;
    color: #626262;
    font-size: 14px;
    font-weight: 500;
}