.adminLogin{
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.adminLogin-cLogo{
    margin-bottom: 10px;
}

.adminLogin-cLogo img{
    height: 50px;
}

.adminLogin-formContainer{
    width: fit-content;
}

.adminLogin-form{
    display: flex;
    flex-direction: column;
    border: 1px solid #C8C7C7;
    border-radius: 10px;
    padding: 1.5rem;
}

.adminLogin-formHeading{
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 10px;
}

.adminLogin-formEmailAdd{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.adminLogin-formPassword{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.adminInputStyle{
    width: 380px;
    height: 37px;
    padding-left: 10px;
    border: 1px solid #C8C7C7;
    border-radius: 5px;
    outline: none;
}

.adminLogin-formBtn{
    display: flex;
    justify-content: flex-end;
}

.adminLogin-text-error{
    font-family: 'Open Sans', sans-serif;
    height: 10px;
    font-size: 11px;
    color: red  ;
}