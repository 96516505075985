.cart {
    position: fixed;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

.cart-container {
    margin: 0rem 3rem;
}

.cart-stepper {
    margin: 2rem 10rem;
}

.viewCart-orderSummaryContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #D9DBE0;
    border-radius: 5px;
    padding: 13px;
}

.viewCart-orderSummaryContainer-orders {
    overflow: hidden;
    height: 265px;
    overflow-y: scroll;
}

.viewCart-orderSummaryContainer-orders::-webkit-scrollbar {
    display: none;
}

.viewCart-orderSummaryContainer-orderDetails {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 15px;
}

.viewCart-orderDetails-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.viewCart-orderDetails-name p {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 700;
}

.viewCart-orderDetails-planAndPrice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    padding: 7px;
    border-radius: 5px;
}

.viewCart-orderDetails-planAndPrice p {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
}

.viewCart-orderTotalAmount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 13px 0px;
}

.viewCart-orderTotalAmount p {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: #000;
}

.viewCartPtc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    background: #ED652B;
    border: 1px solid #ED652B;
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    margin-bottom: 14px;
    cursor: pointer;
}

.viewCartCs-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewCartCs-button button {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    color: #4946C3;
    border: none;
    outline: none;
    background: #ffffff;
    cursor: pointer;
    width: fit-content;
}

@media (min-width : 481px) and (max-width : 768px) {
    .cart-container {
        margin: 0rem 2rem;
    }

    .cart-stepper {
        margin: 1.5rem 5rem;
    }

    .cart-container {
        margin: 0rem 1.5rem;
        overflow: scroll;
    }

    .cart-container::-webkit-scrollbar {
        display: none;
    }

    .viewCart-orderSummaryContainer {
        padding: 5px;
        height: 48vh;
    }

    .viewCart-orderSummaryContainer-orders {
        height: 123px;
    }
}


@media (min-width : 320px) and (max-width : 465px) {
    .cart {
        overflow: scroll;
        height: 100%;
    }

    .cart-container {
        padding: 0rem 1rem 2rem 1rem;
        margin: 0rem;
    }

    .cart-stepper {
        margin: 1.5rem 0rem;
    }
}
