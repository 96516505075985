.currencyDropdown{
    display: flex;
    flex-direction: row;
    font-size: 0.8125rem;
    width: 45%;
    margin-right: 0.4rem;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0.2rem;
    font-size: 0.8125rem;
    color: #363636
}

.currencySpanValue{
    font-size: 0.8125rem;
    font-weight: 700;
    color: #363636;
    margin-right: 0.3rem;
    cursor: pointer;
}