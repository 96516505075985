.ftr {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    border-top: 1px solid #F5F5F5;
    border-bottom: 1.5px solid #E3E3E3;
    padding: 0px 3rem;
    font-family: 'Open Sans', sans-serif;
    background: #efefef;
}

.ftr-logo {
    margin: 1rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 62%;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Open Sans';
}

.ftr-logo img {
    height: 3rem;
}

.ftr-information {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.2fr;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
}

.ourCompany {
    grid-column: 1/2;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.partner {
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.legal {
    grid-column: 3/4;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.services {
    grid-column: 4/5;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contactUs {
    grid-column: 5/6;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ftr-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
}

.ftr-support {
    font-size: 14px;
    line-height: 15px;
    cursor: pointer;
    margin-top: 8px;
}

.ftr-subHeading {
    font-size: 14px;
    line-height: 15px;
    cursor: pointer;
}

.ftr-copyright {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
}

.ftr-icon {
    font-size: 1rem;
    margin-right: 7rem;
}

.ftr-icon span {
    margin-right: 10px;
}

.ftr-copyright-logoAndDetail {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
}

@media (min-width : 481px) and (max-width : 909px) {
    .ftr {
        padding: 0px 2rem;
    }

    .ftr-logo img {
        height: 2.3rem;
    }

    .ftr-information {
        grid-template-columns: 1fr 1.2fr 1.2fr 1.2fr 0.5fr;
        margin-bottom: 1.5rem;
    }

    .ourCompany {
        gap: 5px;
    }

    .ftr-heading {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
    }

    .ftr-subHeading {
        font-size: 12px;
        line-height: 14px;
        cursor: pointer;
        font-weight: 500;
    }

    .partner {
        gap: 5px;
    }

    .legal {
        gap: 5px;
    }

    .services {
        gap: 5px;
    }

    .contactUs {
        gap: 5px;
    }

    .ftr-copyright {
        padding: 0.5rem 2rem;
    }

    .ftr-copyright-logoAndDetail {
        font-size: 0.8rem;
        font-weight: 500;
    }
}

@media (min-width : 910px) and (max-width : 1024px) {
    .ftr-logo img {
        height: 2.5rem;
    }

    .ftr-information {
        grid-template-columns: 0.9fr 1fr 1.1fr 1fr 1fr 0fr;
    }

    .ourCompany {
        gap: 5px;
    }

    .ftr-heading {
        font-size: 0.95rem;
    }

    .ftr-subHeading {
        font-size: 0.85rem;
    }

    .partner {
        gap: 5px;
    }

    .legal {
        gap: 5px;
    }

    .services {
        gap: 5px;
    }

    .contactUs {
        gap: 5px;
    }

    .ftr-copyright-logoAndDetail {
        font-size: 0.9rem;
    }
}

@media (min-width : 320px) and (max-width : 465px) {
    .ftr {
        padding: 0px 1rem;
    }

    .ftr-logo {
        margin: 0.5rem 0rem;
    }

    .ftr-logo img {
        height: 2.3rem;
    }

    .ftr-information {
        width: 100%;
        display: grid;
        grid-template-areas:
            "our-company partner"
            "legal services"
            "contact-us contact-us";
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        margin-bottom: 2rem;
        margin-top: 0.5rem;
    }

    .ourCompany {
        grid-area: our-company;
        gap: 5px;
    }

    .partner {
        grid-area: partner;
        gap: 5px;
    }

    .legal {
        grid-area: legal;
        gap: 5px;
    }

    .services {
        grid-area: services;
        gap: 5px;
    }

    .contactUs {
        grid-area: contact-us;
        gap: 5px;
    }

    .ftr-subHeading {
        font-size: 12px;
    }

    .ftr-copyright {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.4rem 1rem;
        gap: 0.5rem;
    }

    .ftr-copyright-logoAndDetail {
        font-size: 0.8rem;
    }

    .ftr-icon {
        font-size: 1rem;
        margin-right: 0rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

}