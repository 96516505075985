.adminCopyright{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    position: fixed;
}

.adminCopyright-navbar{
    height: 3rem;
    z-index: 1300;
}

.adminCopyright-sidebarAndData{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.adminCopyright-sidebar{
    width: 200px;
}

.adminCopyright-data{
    width: 100%;
}