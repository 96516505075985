.imagesDetailsPage-container {
    margin: 0rem 3rem;
}

.imagesDetailsPage-menu-items {
    color: black;
    text-decoration: none;
}

.imagesDetailsPage-container-searchBar {
    display: flex;
    flex-direction: row;
    margin: 1.3rem 0rem;
    border: 1px solid #D9DBE0;
    border-radius: 5px;
    padding: 6px 0px;
    height: 3rem;
}

.imagesDetailsPage-container-views {
    display: grid;
    grid-template-columns: 0px 7.2fr 2.8fr 0px;
    margin-top: 4rem;
    max-height: 85vh;
    height: 85vh;
}

.imagesDetailsPage-container-views-image {
    display: flex;
    grid-column: 1/3;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.idp-premium-tag {
    font-size: 0.7rem;
    font-weight: 600;
    background: red;
    color: #ffffff;
    padding: 0.2rem;
    position: absolute;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 10px;
}

.imagesDetailsPage-container-views-imageCollection {
    display: flex;
    grid-column: 3/5;
    flex-direction: column;
    align-items: center;
    max-height: 85vh;
    height: 85vh;
    border-radius: 10px;
    justify-content: center;
    padding: 3rem 2rem;
    gap: 0.5rem;
}

.imagesDetailsPage-container-views-imageCollection img {
    height: 25vh;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.image-overlay {
    position: relative;
}

.overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: opacity 0.3s;
    border-radius: 10px;
}

.overlay-text {
    font-size: 13px;
    color: white;
    text-align: center;
    font-family: 'Open Sans';
    font-weight: 600;
}

.overlay-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.imagesDetailsPage-container-optionsAndContentId {
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0rem;
    width: 72%;
    justify-content: space-between;
    align-items: flex-start;
}

.imagesDetailsPage-container-contentId {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.idp-contentId {
    color: #363636;
    font-size: 15px;
    font-weight: 600;
    width: fit-content;
    max-width: fit-content;
    min-width: fit-content
}

.imagesDetailsPage-container-options {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: flex-end;
}

.imageDetailsPage-options-icons {
    align-items: center;
    border: 1px solid #d9dbe0;
    border-radius: 5px;
    color: #666;
    cursor: pointer;
    display: flex;
    font-family: open Sans;
    font-size: 14px;
    margin-right: 14px;
    padding: 7px 10px;
}

.idp-container-titleAndPrice {
    display: flex;
    flex-direction: row;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.idp-container-detailsCommonSty {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
}

.idp-detailsContent-container-cmnSty {
    display: flex;
    flex-direction: row;
    width: 50%;
}

.idp-detailsContent-commonSty {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

.idp-heading {
    color: #363636;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
}

.idp-details {
    color: #363636;
    font-size: 13px;
    margin-bottom: 6px;
    padding-right: 25px;
    word-break: break-all;
}

.idp-descAddSty {
    color: #363636;
    font-size: .8125rem;
    word-break: break-all;
}

.idp-details-priceDesc {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
}

.idp-details-keywordsSty {
    border: 1px solid #D9DBE0;
    padding: 4px 10px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 13px;
    border-radius: 5px;
    font-weight: 500;
}

.idp-details-keywordsSty:hover {
    cursor: pointer;
    border: 1px solid #ED652B;
}

.idp-contributor-nameInitials {
    font-size: 12px;
    font-weight: 600;
    color: #4946C3;
    border: 1px solid #DCDBFF;
    background: #DCDBFF;
    padding: 6px;
    border-radius: 25px;
    margin-right: 10px;
    text-transform: uppercase;
}

.idp-contributor-name {
    font-size: 13px;
    font-weight: 400;
    color: #363636;
    cursor: pointer;
}

.idp-contributor-name:hover {
    text-decoration: underline;
}

.idp-details-addSty {
    border: 1px solid #D9DBE0;
    padding: 2px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 11px;
}

.idp-container-cc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

.idp-container-form {
    display: flex;
    width: 50%;
    flex-direction: column;
    justify-content: flex-start;
}

.idp-container-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem;
}

.imageDetailsPage-container-similarImages {
    margin-bottom: 3rem;
}

.idp-similarImages-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 10px;
}

.idp-contentId-value {
    font-size: 0.8rem;
    font-weight: 500;
}

.idp-addToCartBtn {
    background: #ED652B;
    color: #ffffff;
    width: 103px;
    height: 35px;
    float: left;
    font-weight: 700;
    border: 1px solid #ED652B;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}

@media (min-width : 481px) and (max-width : 768px) {
    .imagesDetailsPage-container {
        margin: 0rem 1.5rem;
    }

    .imagesDetailsPage-container-searchBar {
        display: flex;
        flex-direction: row;
        margin: 1rem 0rem;
        border: 1px solid #D9DBE0;
        border-radius: 5px;
        align-items: center;
        height: 2.5rem;
        padding: 3px;
    }

    .imagesDetailsPage-container-views {
        margin-top: 2.5rem;
        height: 380px;
    }

    .imagesDetailsPage-container-views-image {
        height: 380px;
        border-radius: 10px;
    }

    .imagesDetailsPage-container-views-imageCollection {
        margin: 0rem 0.6rem;
        height: 380px;
        border-radius: 10px;
    }

    .imagesDetailsPage-container-views-imageCollection img {
        height: 110px;
        margin-bottom: 0.7rem;
        object-fit: cover;
        border-radius: 10px;
    }

    .imagesDetailsPage-container-optionsAndContentId {
        margin: 0.3rem 0rem;
        width: 72%;
    }

    .imagesDetailsPage-container-contentId {
        width: 100%;
        gap: 0.3rem;
    }

    .idp-contentId {
        font-size: 0.75rem;
        font-weight: 600;
        color: #363636;
    }

    .idp-contentId-value {
        font-size: 0.65rem;
        font-weight: 500;
    }

    .imageDetailsPage-options-icons {
        font-size: 12px;
        padding: 5px 7px;
        margin-right: 7px;
    }

    .idp-addToCartBtn {
        width: 70px;
        height: 28px;
        font-weight: 600;
        border: 1px solid #ED652B;
        font-size: 11px;
    }

    .idp-container-titleAndPrice {
        width: 80%;
    }

    .idp-heading {
        font-size: 0.8rem;
        margin-bottom: 0rem;
    }

    .idp-details {
        font-size: 0.7rem;
        margin-right: 1.2rem;
    }

    .idp-container-desc {
        width: 80%;
        margin-bottom: 0.7rem;
    }

    .idp-details-priceDesc {
        font-size: 0.7rem;
        color: #363636;
        word-break: break-all;
    }

    .idp-contributor-nameInitials {
        font-size: 0.7rem;
        padding: 4px;
        margin-right: 3px;
    }

    .idp-contributor-name {
        font-size: 0.7rem;
    }

    .idp-similarImages-heading {
        font-size: 1.15rem;
    }

    .idp-container-detailsCommonSty {
        margin-bottom: 0.7rem;
    }
}

@media (min-width : 769px) and (max-width : 909px) {
    .imagesDetailsPage-container {
        margin: 0rem 1.5rem;
    }

    .imagesDetailsPage-container-searchBar {
        display: flex;
        flex-direction: row;
        margin: 1rem 0rem;
        border: 1px solid #D9DBE0;
        border-radius: 5px;
        align-items: center;
        height: 2.5rem;
        padding: 3px;
    }

    .imagesDetailsPage-container-views {
        margin-top: 2.5rem;
        height: 420px;
    }

    .imagesDetailsPage-container-views-image {
        height: 420px;
        border-radius: 10px;
    }

    .imagesDetailsPage-container-views-imageCollection {
        margin: 0rem 0.6rem;
        height: 420px;
    }

    .imagesDetailsPage-container-views-imageCollection img {
        height: 120px;
        margin-bottom: 0.7rem;
        object-fit: cover;
        border-radius: 10px;
    }

    .imagesDetailsPage-container-optionsAndContentId {
        margin: 0.3rem 0rem;
        width: 72%;
    }

    .imagesDetailsPage-container-contentId {
        width: 100%;
        gap: 0.3rem;
    }

    .idp-contentId {
        font-size: 0.75rem;
        font-weight: 600;
        color: #363636;
    }

    .idp-contentId-value {
        font-size: 0.65rem;
        font-weight: 500;
    }

    .imageDetailsPage-options-icons {
        font-size: 12px;
        padding: 5px 7px;
        margin-right: 7px;
    }

    .idp-addToCartBtn {
        width: 70px;
        height: 28px;
        font-weight: 600;
        border: 1px solid #ED652B;
        font-size: 11px;
    }

    .idp-container-titleAndPrice {
        width: 80%;
    }

    .idp-heading {
        font-size: 0.8rem;
        margin-bottom: 0rem;
    }

    .idp-details {
        font-size: 0.7rem;
        margin-right: 1.2rem;
    }

    .idp-container-desc {
        width: 80%;
        margin-bottom: 0.7rem;
    }

    .idp-details-priceDesc {
        font-size: 0.7rem;
        color: #363636;
        word-break: break-all;
    }

    .idp-contributor-nameInitials {
        font-size: 0.7rem;
        padding: 4px;
        margin-right: 3px;
    }

    .idp-contributor-name {
        font-size: 0.7rem;
    }

    .idp-similarImages-heading {
        font-size: 1.15rem;
    }

    .idp-container-detailsCommonSty {
        margin-bottom: 0.7rem;
    }
}

@media (min-width : 910px) and (max-width : 1024px) {
    .imagesDetailsPage-container {
        margin: 0rem 2.5rem;
    }

    .imagesDetailsPage-container-views {
        margin-top: 3rem;
    }

    .imagesDetailsPage-container-views-image {
        border-radius: 10px;
    }

    .imagesDetailsPage-container-views-imageCollection {
        margin: 0rem 0.8rem;
        border-radius: 10px;
    }

    .imagesDetailsPage-container-views-imageCollection img {
        border-radius: 10px;
        margin-bottom: 0.8rem;
        height: 25vh;
    }

    .imagesDetailsPage-container-optionsAndContentId {
        width: 72%;
        margin: 0.5rem 0rem 0.8rem 0rem;
    }

    .imagesDetailsPage-container-contentId {
        gap: 0.4rem;
        width: 100%;
    }

    .idp-contentId {
        font-size: 0.8rem;
    }

    .idp-contentId-value {
        font-size: 0.7rem;
        font-weight: 500;
    }

    .imageDetailsPage-options-icons {
        font-size: 0.8rem;
        padding: 6px;
        margin-right: 13px;
    }

    .idp-addToCartBtn {
        height: 35px;
        font-size: 0.8rem;
    }

    .idp-heading {
        font-size: 0.9rem;
        margin-bottom: 4px;
    }

    .idp-details {
        font-size: 0.8rem;
    }

    .idp-details-priceDesc {
        font-size: 0.8rem;
    }

    .idp-similarImages-heading {
        font-size: 21px;
        margin-bottom: 0px;
    }
}

@media (min-width : 1025px) and (max-width : 1200px) {
    .imagesDetailsPage-container-views-imageCollection {
        margin: 0rem 1rem;
        border-radius: 10px;
    }

    .imagesDetailsPage-container-views-imageCollection img {
        border-radius: 10px;
    }

    .imagesDetailsPage-container-optionsAndContentId {
        width: 72%;
    }
}

@media (min-width : 320px) and (max-width : 465px) {
    .imagesDetailsPage-container {
        margin: 0rem 1rem;
    }

    .imagesDetailsPage-container-searchBar {
        margin: 1rem 0rem;
        height: 2.5rem;
        padding: 2px 0px;
    }

    .imagesDetailsPage-container-views {
        display: flex;
        grid-template-columns: 0px 7.2fr 2.8fr 0px;
        margin-top: 2rem;
        max-height: 90vh;
        height: 50vh;
    }

    .imagesDetailsPage-container-views-imageCollection {
        display: none;
    }

    .imagesDetailsPage-container-optionsAndContentId {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0.5rem 0;
        width: 100%;
    }

    .imagesDetailsPage-container-options {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .imageDetailsPage-options-icons {
        font-size: 12px;
        padding: 5px 11px;
        margin-right: 0rem;
    }

    .imagesDetailsPage-container-contentId {
        margin-bottom: 0.5rem;
    }

    .idp-contentId {
        font-size: 13px;
        font-weight: 600;
    }

    .idp-contentId-value {
        font-size: 11px;
        font-weight: 500;
    }

    .idp-addToCartBtn {
        width: 95px;
        height: 32px;
        font-weight: 600;
        font-size: 0.8rem;
    }

    .idp-container-titleAndPrice {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .idp-heading {
        font-size: 13px;
        margin-bottom: 0px;
    }

    .idp-details {
        font-size: 12px;
        margin-bottom: 0px;
        padding-right: 0px;
    }

    .idp-details-priceDesc {
        font-size: 12px;
    }

    .idp-container-desc {
        width: 100%;
        margin-bottom: 0.5rem;
    }

    .idp-container-detailsCommonSty {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
        width: 100%;
        margin-bottom: 0.5rem;
        gap: 0.5rem;
    }

    .idp-detailsContent-container-cmnSty {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .idp-contributor-nameInitials {
        font-size: 11px;
        padding: 4px;
        margin-right: 5px;
    }

    .idp-contributor-name {
        font-size: 12px;
    }

    .idp-similarImages-heading {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 2rem;
    }
}

.idp-detailsContent-price-selection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: none;
}