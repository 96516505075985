.editorialPage-banner {
    height: fit-content;
    width: 100%;
    position: relative;
}

.editorial-banner-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.editorialPageHeaderHeading {
    height: 45px;
    width: 350px;
    margin-bottom: 10px;
}

.editorialPage-menu-items {
    color: #ffffff;
    text-decoration: none;
}

.editorialPage-SearchBar {
    display: flex;
    align-items: center;
    width: 85%;
    min-width: 400px;
    max-width: 100%;
    height: 45px;
    background-color: #d9dbe0;
    margin-top: 20px;
    border-radius: 5px;
}

.editorialPage-pageContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    gap: 1rem;
    color: #ED652B;
}

.editorialPageHeaderHeight {
    height: 65vh;
}

@media (min-width : 481px) and (max-width : 800px) {
    .editorialPage-SearchBar {
        margin-top: 0.6rem;
        height: 2.2rem;
        width: 90%;
    }

    .editorialPage-pageContent {
        padding: 3rem 0rem;
        font-size: 1.5rem;
    }
}

@media (min-width : 801px) and (max-width : 909px) {
    .editorialPage-SearchBar {
        margin-top: 0.6rem;
        height: 2.5rem;
        width: 75%;
    }

    .editorialPage-pageContent {
        padding: 3rem 0rem;
        font-size: 1.5rem;
    }
}

@media (min-width : 320px) and (max-width : 465px) {
    .editorialPageHeaderHeight {
        height: 45vh;
    }

    .editorialPage-SearchBar {
        min-width: 200px;
        width: 100%;
        height: 2.5rem;
    }

    .editorialPage-pageContent {
        padding: 3rem 0rem;
        font-size: 20px;
    }
}