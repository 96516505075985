.scroll-to-top-button {
    position: fixed;
    bottom: 40px;
    right: 32px;
    z-index: 1000;
    background-color: #fff;
    color: #4946C3;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none; /* Remove the default focus outline */
  }
  
  .scroll-to-top-button.show {
    opacity: 1;
  }
  
  .scroll-to-top-button svg {
    width: 100%;
    height: 100%;
  }
  