@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,500;0,600;0,700;0,800;1,400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-color: #4e4e4e;
    scrollbar-width: thin;
}

body::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

::-webkit-scrollbar {
    width: 0.65em;
    height: 0.65em;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #989898;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

li {
    list-style: none;
}

.ff-OS {
    font-family: 'Open Sans' sans-serif;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fs-14 {
    font-size: 0.8rem;
}

.fs-16 {
    font-size: 1rem;
}

.fs-28 {
    font-size: 28px;
}

.fs-24 {
    font-size: 24px;
}

.fs-22 {
    font-size: 1.3rem;
}

.fs-12 {
    font-size: 12px;
}


body,
button,
a {
    font-family: 'Open Sans', sans-serif;
}

li {
    list-style: none;
}

a {
    all: unset;
    cursor: pointer;
}

.txt-center {
    text-align: center !important;
}

.pt-1 {
    padding-top: 1rem;
}

.pb-1 {
    padding-bottom: 1rem;
}

.outline-error {
    border: 1px solid rgb(255, 103, 103);
}

.navbar-menu-items {
    font-family: 'Open Sans', sans-serif;
    color: black;
    text-decoration: none;
    font-weight: 500;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.ft_ul_cmn {
    text-decoration: underline;
}

.ft_mg_btm {
    margin-bottom: 2.2rem;
}

.ft-font-commonSty {
    font-family: 'Open Sans', sans-serif;
}

.navbar-logoStyle{
    height: 2.5rem;
}

.navbar-logoStyle-common{
    height: 3rem;
}

.seller{
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.seller-container{
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: column;
    height: 88vh;
    width: 35vw;
    border: 1px solid #c8c7c7;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    padding: 1rem;
}

@media (min-width : 320px) and (max-width : 465px){
    .navbar-logoStyle-common{
        height: 2.5rem;
    }
}