.header{
    display: grid;
    grid-template-columns: 0rem 1fr 5fr 2fr 0rem;
    align-items: center;
}

.header-container{
    display: grid;
    grid-column: 3/4;
}

.header-heading{
    width: fit-content;
    font-family: 'Open Sans';
    margin-bottom: 14px;
    font-size: 27px;
    font-weight: 800;
    color: #FFFFFF;
}

.header-subHeading h4{
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #fff;
}

.header-trending{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #fff;
    margin-top: 0.5rem;
}

@media (min-width : 481px) and (max-width : 909px){
    .header{
        height: 331px;
    }

    .header-heading{
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }

    .header-subHeading h4{
        font-size: 1rem;
    }

    .header-trending {
        font-size: 12px;
        margin-top: 0.4rem;
    }
}

@media (min-width : 910px) and (max-width : 1024px){
    .header {
        display: grid;
        align-items: center;
    }

    .header-heading {
        margin-bottom: 10px;
        font-size: 22px;
    }

    .header-subHeading h4 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: #fff;
    }
}

@media (min-width : 320px) and (max-width : 465px){
    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    .header-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .header-trending{
        margin-top: 0.2rem;
        font-size: 10px;
    }
}

