.about{
    background: #efefef;
    color: #626262;
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 14px;
}

.about-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 3rem 5rem 3rem;  
    min-height: calc(100vh - (4rem + 24rem));
}

.about-contentContainer-CommonSty{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.about-heading{
    display: flex;
    flex-direction: row;
    font-size: 27px;
    font-weight: 600;
    margin: 1rem 0rem;
}

.about-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
}