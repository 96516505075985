.mission{
    background: #efefef;
    color: #626262;
    font-family: 'Merriweather Sans', sans-serif;
    font-size: 14px;
}

.mission-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 3rem 5rem 3rem;  
    min-height: calc(100vh - 28rem);
}

.mission-heading{
    display: flex;
    flex-direction: row;
    font-size: 27px;
    font-weight: 600;
    margin: 1rem 0rem;
}

.mission-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-weight: 400;
    gap: 1rem;
}